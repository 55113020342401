import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import axios from "axios";
import { baseURL } from "../../../global/global";
import { removeAlert, setAlert } from "../../../actions/alert";
import { getTtsData, generateTts } from "../../../actions/tts";
import {
  fetchLoadTemplate,
  fetchUploadedAudio,
  fetchUploadedLogo,
} from "../../../actions/video";
import { generateTtsAudio } from "../../../actions/tts";

import { connect } from "react-redux";
import Alert from "../../alert/Alert";
import { frontURL } from "../../../global/global";
import HoverVideoPlayer from "react-hover-video-player";
import ConfirmDelete from "../../confirmdelete/ConfirmDelete";
import Loader from "../../loader/Loader";

const EditPopup = ({
  openCloseFunction,
  tabName,
  changeImages,
  setAlert,
  auth: { user, loading },
  fetchLoadTemplate,
  loadingTemplate,
  fetchUploadedLogo,
  fetchUploadedAudio,
  setLogoUrl,
  setAudioUrl,
  removeAlert,
  getTtsData,
  ttsValue,
  generateTts,
  generateTtsAudio,
  audioUrlSource,
}) => {
  const [pageNum, setPageNum] = useState(1);
  const [audioPageNum, setAudioPageNum] = useState(1);
  const [ImgAndAudio, setImgAndAudio] = useState([]);
  const [audio, setAudio] = useState([]);
  const [keyWord, setkeyWord] = useState("");
  const [imgSrc, setImgSrc] = useState({});
  const [logoSrc, setLogoSrc] = useState({});
  const [audioSrc, setAudioSrc] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [user_id, setUserId] = useState("");
  const [uploadData, setUploadData] = useState([]);
  const [uploadAudioData, setUploadAudioData] = useState([]);
  const [uploadLogoData, setUploadLogoData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [imagesSearchbool, setImagesSearchBool] = useState(false);
  const [audioObject, setAudioObject] = useState({});
  const [audioEvent, setAudioEvent] = useState({});
  const [isBottom, setIsBottom] = useState(false);
  const [imagesOrVideo, setImagesOrVideo] = useState("image");
  const [loader, setLoader] = useState(false);
  const [videoLibrary, setVideoLibrary] = useState([]);
  const [videoLibraryPageNo, setVideoLibraryPageNo] = useState(1);
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const [deleteData, setDeleteData] = useState({});
  const [scrollNot, setScrollNot] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [tts, setTts] = useState([]);
  const [ttsLangVoice, setTtsLangVoice] = useState({
    language: "-Select-",
    voice: "-Select-",
    text: "",
  });
  const [ttsVoice, setTtsVoice] = useState([]);
  const [ttsAudioUrl, setTtsAudioUrl] = useState([]);
  const [ttsLoader, setTtsLoader] = useState(false);
  const [ttsName, setTtsName] = useState("");
  const [libraryAudioDuration, setLibraryAudioDuration] = useState([]);
  const [yourAudioDuration, setYourAudioDuration] = useState([]);
  const [ttsAudioDuration, setTtsAudioDuration] = useState([]);

  // Edit Images
  useEffect(() => {
    if (user) {
      setUserId(loading || !user.user_id ? "" : user.user_id);
    }
  }, [user, loading]);

  useEffect(() => setAudioSrc(audioUrlSource), [audioUrlSource]);

  // Set audio library duration
  useEffect(() => {
    if (audio.length > 0) {
      audio.map((value) =>
        new Audio(value.url).addEventListener("loadedmetadata", (e) => {
          setLibraryAudioDuration((val) => [
            ...val,
            parseInt(e.target.duration),
          ]);
        })
      );
    }
  }, [audio]);

  // Set your library duration
  useEffect(() => {
    if (ttsAudioUrl.length > 0) {
      ttsAudioUrl.map((value) =>
        new Audio(value.audio_url).addEventListener("loadedmetadata", (e) => {
          setTtsAudioDuration((val) => [...val, parseInt(e.target.duration)]);
        })
      );
    }
  }, [ttsAudioUrl]);

  // Set tts library duration
  useEffect(() => {
    if (uploadAudioData.length > 0) {
      uploadAudioData.map((value) =>
        new Audio(value.audio_url).addEventListener("loadedmetadata", (e) => {
          setYourAudioDuration((val) => [...val, parseInt(e.target.duration)]);
        })
      );
    }
  }, [uploadAudioData]);

  // Fetching tts data
  useEffect(() => {
    setTtsAudioUrl(
      ttsValue.ttsAudio.length > 0
        ? ttsValue.ttsAudio.map((value) => ({
          audio_url: value.url,
          name: value.name,
        }))
        : []
    );
  }, [ttsValue.ttsAudio]);

  // call tts api for fetching data
  useEffect(() => {
    if (user_id !== "") {
      getTtsData(user_id);
    }
  }, [user_id, getTtsData]);

  // getting tts data from redux
  useEffect(() => {
    if (ttsValue.tts !== null) {
      setTts(ttsValue.tts);
    }
  }, [ttsValue]);

  // fetching uploaded image
  useEffect(() => {
    if (user_id !== "") {
      fetchLoadTemplate({ type: "image", user_id: user_id });
    }
  }, [fetchLoadTemplate, user_id]);

  useEffect(() => {
    setUploadData(loadingTemplate.uploadImage);
  }, [loadingTemplate.loading, loadingTemplate.uploadImage]);

  // fetching uploaded audio
  useEffect(() => {
    if (user_id !== "") {
      fetchUploadedAudio({ type: "audio", user_id: user_id });
    }
  }, [fetchUploadedAudio, user_id]);

  useEffect(() => {
    setUploadAudioData(loadingTemplate.uploadAudio);
  }, [loadingTemplate.loading, loadingTemplate.uploadAudio]);

  // fetching uploaded logo
  useEffect(() => {
    if (user_id) {
      fetchUploadedLogo({ type: "logo", user_id: user_id }, setUploadLoader);
    }
  }, [fetchUploadedLogo, user_id]);

  useEffect(() => {
    setUploadLogoData(loadingTemplate.uploadLogo);
  }, [loadingTemplate.loading, loadingTemplate.uploadLogo]);

  const searchImagesAndAudio = (e) => {
    e.preventDefault();
    setScrollNot(false);
    if (imagesOrVideo === "image") {
      setPageNum(1);
      getImagesAndAudio()();
    } else if (imagesOrVideo === "video") {
      setVideoLibraryPageNo(1);
      getImagesAndAudio()();
    }
  };

  // const LoadMoreImages = () => {
  //   setImagesSearchBool(true);
  //   if (keyWord !== "") {
  //     setPageNum((value) => ++value);
  //   }
  // };

  const LoadMoreAudio = () => {
    setAudioPageNum((value) => ++value);
  };

  // useEffect(() => {
  //   getAudio();
  // }, []);

  const getImagesAndAudio = useCallback(
    () => async () => {
      if (keyWord === "") {
        return;
      }
      if (imagesOrVideo === "image") {
        if (pageNum === 1) {
          setImgAndAudio([]);
        }
      } else if (imagesOrVideo === "video") {
        if (videoLibraryPageNo === 1) {
          setVideoLibrary([]);
        }
      }

      setLoader(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        type: imagesOrVideo === "video" ? "video" : "images",
        keyword: keyWord,
        page_number: imagesOrVideo === "video" ? videoLibraryPageNo : pageNum,
      });

      try {
        const res = await axios.post(
          `${baseURL}load-library-images`,
          body,
          config
        );

        if (res.data.status === true) {
          if (res.data.data === false) {
            setScrollNot(true);
            setLoader(false);
            if (imagesOrVideo === "image") {
              setAlert("!Sorry, No Images Found", "danger");
            } else if (imagesOrVideo === "video") {
              setAlert("!Sorry, No Videos Found", "danger");
            }
            return;
          }
          setLoader(false);
          if (imagesOrVideo === "image") {
            if (res.data.data === null) {
              setScrollNot(true);
              setAlert("Sorry!, No More Images", "danger");
            } else {
              setImgAndAudio((value) => [...value, ...res.data.data]);
            }
          } else if (imagesOrVideo === "video") {
            if (res.data.data === null) {
              setScrollNot(true);
              setAlert("Sorry!, No More Videos", "danger");
            } else {
              setVideoLibrary((value) => [...value, ...res.data.data]);
            }
          }
          setIsBottom(false);
        }
      } catch (err) {
        setLoader(false);
        console.log(err.response);
      }
    },
    [keyWord, pageNum, setAlert, videoLibraryPageNo, imagesOrVideo]
  );

  // onScrolling load images
  useEffect(() => {
    if (pageNum > 1 && imagesSearchbool) {
      getImagesAndAudio()();
    }
  }, [pageNum, getImagesAndAudio, imagesSearchbool]);

  // onScrolling load videos
  useEffect(() => {
    if (videoLibraryPageNo > 1 && imagesSearchbool) {
      getImagesAndAudio()();
    }
  }, [videoLibraryPageNo, getImagesAndAudio, imagesSearchbool]);

  // Upload picture

  const uploadImages = async (e) => {
    if (!e.target.files[0]) return;
    const fileSize = e.target.files[0].size / (1024 * 1024);
    const fileName = e.target.files[0].name;
    const fileExtensionArray = fileName.split(".");
    const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];

    if (
      (fileExtension === "mp4" && fileSize <= 100) ||
      ((fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png") &&
        fileSize <= 5)
    ) {
      if (
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png" ||
        fileExtension === "mp4"
      ) {
        setUploadLoader(true);
        setSelectedFile(e.target.files[0]);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append(
          "upload_type",
          fileExtension === "mp4" ? "video" : "image"
        );
        formData.append("user_id", user_id);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        try {
          const res = await axios.post(
            `${baseURL}upload-project-image`,
            formData,
            config
          );

          if (res.data.status === true) {
            fetchLoadTemplate({ type: "image", user_id: user_id });
            setSelectedFile({});
            setUploadLoader(false);
          } else {
            setUploadLoader(false);
          }
        } catch (err) {
          console.log(err.response);
          setUploadLoader(false);
        }
      } else {
        setAlert(
          "The file extension you are trying to upload is not allowed, extension allowed only(png, jpeg, jpg,mp4)",
          "danger"
        );
      }
    } else {
      setAlert(
        fileExtension === "mp4"
          ? "You cannot upload videos more then 100MB in size"
          : "You cannot upload images more then 5MB in size",
        "danger"
      );
    }
  };

  // Edit Audio/////////////

  const getAudio = useCallback(
    () => async () => {
      if (audioPageNum === 1) {
        setAudio([]);
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      setLoader(true);
      const body = JSON.stringify({ page_number: audioPageNum });

      try {
        const res = await axios.post(
          `${baseURL}load-library-audio`,
          body,
          config
        );

        if (res.data.status === true) {
          setLoader(true);
          setAudio((value) => [...value, ...res.data.data]);
          setIsBottom(false);
        }
      } catch (err) {
        setLoader(true);
        console.log(err.response);
      }
    },
    [audioPageNum]
  );

  useEffect(() => {
    if (tabName.first === "Audio Library") {
      getAudio()();
    }
  }, [audioPageNum, getAudio, tabName.first]);

  // Upload Audio

  // const uploadAudio = async (e) => {
  //   console.log(e.target.files[0]);
  //   if (!e.target.files[0]) return;
  //   const fileSize = e.target.files[0].size / (1024 * 1024);
  //   const fileName = e.target.files[0].name;

  //   if (fileSize <= 10) {
  //     const fileExtensionArray = fileName.split(".");
  //     const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
  //     if (fileExtension === "mp3") {
  //       setUploadLoader(true);
  //       setSelectedFile(e.target.files[0]);
  //       const formData = new FormData();
  //       formData.append("file", e.target.files[0]);
  //       formData.append("upload_type", "audio");
  //       formData.append("user_id", user_id);

  //       const config = {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       };

  //       try {
  //         const res = await axios.post(
  //           `${baseURL}project-file-upload`,
  //           formData,
  //           config
  //         );

  //         if (res.data.status === true) {
  //           fetchUploadedAudio({ type: "audio", user_id: user_id });
  //           setUploadLoader(false);
  //           setSelectedFile({});
  //         }
  //       } catch (err) {
  //         console.log(err.response);
  //         setUploadLoader(false);
  //       }
  //     } else {
  //       setAlert(
  //         "The file extension you are trying to upload is not allowed, extension allowed only(mp3)",
  //         "danger"
  //       );
  //     }
  //   } else {
  //     setAlert("You cannot upload audio more then 10MB in size", "danger");
  //   }
  // };
  const fileInputRef = useRef(null);

  const uploadAudio = async (e) => {
    console.log(e.target.files[0]);
    if (!e.target.files[0]) return;
    const fileSize = e.target.files[0].size / (1024 * 1024);
    const fileName = e.target.files[0].name;

    if (fileSize <= 10) {
      const fileExtensionArray = fileName.split(".");
      const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
      if (fileExtension === "mp3") {
        setUploadLoader(true);
        setSelectedFile(e.target.files[0]);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("upload_type", "audio");
        formData.append("user_id", user_id);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        try {
          const res = await axios.post(
            `${baseURL}project-file-upload`,
            formData,
            config
          );

          if (res.data.status === true) {
            fetchUploadedAudio({ type: "audio", user_id: user_id });
            setUploadLoader(false);
            setSelectedFile({});
          }
        } catch (err) {
          console.log(err.response);
          setUploadLoader(false);
        }
      } else {
        setAlert(
          "The file extension you are trying to upload is not allowed, extension allowed only(mp3)",
          "danger"
        );
      }
    } else {
      setAlert("You cannot upload audio more than 10MB in size", "danger");
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Edit Logo

  // Upload Logo
  const uploadLogo = async (e) => {
    if (!e.target.files[0]) return;
    const fileSize = e.target.files[0].size / (1024 * 1024);
    const fileName = e.target.files[0].name;

    if (fileSize <= 5) {
      const fileExtensionArray = fileName.split(".");
      const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
      if (
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      ) {
        setUploadLoader(true);
        setSelectedFile(e.target.files[0]);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("upload_type", "logo");
        formData.append("user_id", user_id);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        try {
          const res = await axios.post(
            `${baseURL}project-file-upload`,
            formData,
            config
          );

          if (res.data.status === true) {
            fetchUploadedLogo(
              { type: "logo", user_id: user_id },
              setUploadLoader
            );

            setSelectedFile({});
          }
        } catch (err) {
          console.log(err.response);
          setUploadLoader(false);
          setSelectedFile({});
        }
      } else {
        setAlert(
          "The file extension you are trying to upload is not allowed, extension allowed only(png, jpeg, jpg)",
          "danger"
        );
      }
    } else {
      setAlert("You cannot upload more then 5MB in size", "danger");
    }
  };

  // Close delete modal
  const close = () => {
    setModal({ isOpen: false });
  };

  // delete project by modal
  const deleteProjectByModal = () => {
    deleteUploadedData(deleteData);
  };

  // Delete Upload File
  const deleteUploadedData = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(
      data.type === "tts" ? { user_id: user_id, url: data.url } : data
    );

    try {
      const res = await axios.post(
        `${baseURL}${data.type === "tts" ? "delete-tts" : "delete-user-uploaded-file"
        }`,
        body,
        config
      );

      if (res.data.status === true) {
        setAlert(
          data.type === "tts" ? res.data.message : res.data.data,
          "danger"
        );
        if (data.type === "logo") {
          fetchUploadedLogo(
            { type: "logo", user_id: user_id },
            setUploadLoader
          );
        } else if (data.type === "audio") {
          fetchUploadedAudio({ type: "audio", user_id: user_id });
        } else if (data.type === "image") {
          fetchLoadTemplate({ type: "image", user_id: user_id });
        } else if (data.type === "tts") {
          generateTtsAudio({ user_id });
        }
        setDeleteId("");
        setAudioSrc("");
        setImgSrc({});
        setLogoSrc({});
        setDeleteData({});
      }
      close();
    } catch (err) {
      console.log(err.response);
    }
  };

  // play Audio
  useEffect(() => {
    if (
      !(
        Object.keys(audioObject).length === 0 &&
        audioObject.constructor === Object
      )
    ) {
      audioObject.play();
    }
  }, [audioObject]);

  useEffect(() => {
    if (
      !(
        Object.keys(audioObject).length === 0 &&
        audioObject.constructor === Object
      )
    ) {
      audioObject.onended = () => {
        audioEvent.src = "./assets/images/play_1.svg";
      };
    }
  }, [audioEvent, audioObject]);

  const playOrPauseAudio = (audio) => {
    if (
      Object.keys(audioObject).length === 0 &&
      audioObject.constructor === Object
    ) {
      setAudioObject(audio);
      return;
    } else {
      audioObject.pause();
    }

    setAudioObject(audio);
  };

  useEffect(() => {
    if (keyWord !== "" || document.getElementById("audio_scroll")) {
      const getScrollId =
        document.getElementById("images_scroll") ||
        document.getElementById("audio_scroll");

      if (getScrollId) {
        getScrollId.addEventListener("scroll", handleScroll);
      }
      return () => {
        if (getScrollId) {
          getScrollId.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [keyWord]);

  function handleScroll() {
    const getScrollId =
      document.getElementById("images_scroll") ||
      document.getElementById("audio_scroll");
    if (document.getElementById("library").classList.value.includes("active")) {
      if (getScrollId) {
        const scrollTop = getScrollId.scrollTop;
        const scrollHeight = getScrollId.scrollHeight;

        if (scrollTop + window.innerHeight + 100 >= scrollHeight) {
          setIsBottom(true);
        }
      } else {
        return;
      }
    }
  }

  useEffect(() => {
    if (!scrollNot) {
      if (isBottom) {
        if (document.getElementById("images_scroll")) {
          setImagesSearchBool(true);
          if (keyWord !== "") {
            if (imagesOrVideo === "image") {
              setPageNum((value) => ++value);
            } else if (imagesOrVideo === "video") {
              setVideoLibraryPageNo((value) => ++value);
            }
          }
        } else if (document.getElementById("audio_scroll")) {
          LoadMoreAudio();
        }
      }
    }
  }, [isBottom, keyWord, imagesOrVideo, scrollNot]);

  // Scroll finshed //

  // Tts update data(all Tts Funciton) //
  const ttsUpdation = (e) => {
    let target = e.target;

    setTtsLangVoice((value) => ({
      ...value,
      [target.name]: target.value,
    }));
  };

  useEffect(() => {
    if (ttsLangVoice.language !== "-Select-") {
      let voices = tts.find(
        (value) => value.language_code === ttsLangVoice.language
      );
      setTtsVoice(() => {
        return voices.voices.length > 0 ? voices.voices : [];
      });
    }
  }, [ttsLangVoice.language, tts]);

  // Generate Tts
  const generate = async () => {
    setTtsLoader(true);
    const data = {
      user_id,
      language: ttsLangVoice.language,
      voice: ttsLangVoice.voice,
      text: ttsLangVoice.text,
      name: ttsName,
    };
    await generateTts(data, setTtsLoader);
  };

  // Tts function end

  return (
    <div
      id="confirm-del"
      className="modal fade show d-block"
      style={{ background: "rgba(0,0,0,0.9)", zIndex:"999999" }}
      tabIndex={-1}
      role="dialog"
      data-backdrop="static"
      aria-labelledby="delete-warn"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-lg modal-dialog-centered mx-auto px-2"
        role="document"
        // style={{ maxWidth: "75vw" }}
      >
        <div
          className="modal-content"
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <button
            type="button"
            className="close mb-3 text-right"
            data-dismiss="modal"
            aria-label="Close"
            style={{
              opacity: "1",
              outline: "none",
            }}
            onClick={() => {
              openCloseFunction((value) => !value);
              if (
                !(
                  Object.keys(audioObject).length === 0 &&
                  audioObject.constructor === Object
                )
              ) {
                audioObject.pause();
              }
              setAudioObject({});
            }}
          >
            <img
              className="img-fluid"
              src="./assets/images/close.svg"
              alt="Close"
            />
          </button>
          <div className="modal-body bg-white text-center m-0 p-0">
            <EditingStyle>
              <div
                className="edit_part"
                id="editScroll"
                style={{ height: "80vh !important" }}
              >
                <Alert />
                <ul
                  className="nav nav-tabs d-flex edit_nav border border-0"
                  role="tablist"
                >
                  <li className="nav-item flex-fill text-center ">
                    <a
                      className="nav-link active edit_tab"
                      data-toggle="tab"
                      href="#library"
                    >
                      {tabName.first === "Images Library" ||
                        tabName.first === "Logo Library" ? (
                        <>
                          <i className="fa fa-camera" aria-hidden="true"></i>{" "}
                          Media Library
                        </>
                      ) : (
                        <>
                          <i
                            className="fa fa-file-audio-o"
                            aria-hidden="true"
                          ></i>{" "}
                          {tabName.first}
                        </>
                      )}
                    </a>
                  </li>

                  <li className="nav-item flex-fill text-center ">
                    <a
                      className={`nav-link edit_tab`}
                      data-toggle="tab"
                      href="#your"
                    >
                      {tabName.second === "Your Images" ||
                        tabName.second === "Your logo" ? (
                        <>
                          <i
                            className="fa fa-user-circle-o"
                            aria-hidden="true"
                          ></i>{" "}
                          Your Library
                        </>
                      ) : (
                        <>
                          <i
                            className="fa fa-user-circle-o"
                            aria-hidden="true"
                          ></i>{" "}
                          {tabName.second}
                        </>
                      )}
                    </a>
                  </li>
                  {tabName.third !== "Upload Images" &&
                    tabName.third !== "Upload Logo" ? (
                    <li className="nav-item flex-fill text-center">
                      <a
                        className="nav-link edit_tab"
                        data-toggle="tab"
                        href="#upload"
                      >
                        {tabName.third}
                      </a>
                    </li>
                  ) : null}
                </ul>

                {/* ***********************Show Logo ********************/}
                {tabName.first === "Logo Library" ? (
                  <>
                    {/* <!-- Tab panes --> */}
                    <div
                      className="tab-content"
                      id={
                        tabName.first === "Logo Library"
                          ? "images_scroll"
                          : "tem_img"
                      }
                    >
                      {/* Library Section */}
                      <div id="library" className="container tab-pane active">
                        <br />
                        <div className="row general_row d-flex justify-content-center search-row">
                          <div className="col-11 col-md-9 search-area">
                            <div
                              className="row d-flex justify-content-around align-items-center"
                              style={{ height: "100%" }}
                            >
                              <div
                                className="col-10 p-0"
                                style={{ marginBottom: "20px" }}
                              >
                                <form onSubmit={searchImagesAndAudio}>
                                  <div className="row ">
                                    <div className="col-10 p-0">
                                      <div className="form-group m-0">
                                        <label className="input-label">
                                          Search for media{" "}
                                        </label>
                                        <input
                                          className="form-control input-dark-accnt personal-info input-radius"
                                          type="text"
                                          name="search"
                                          placeholder="Enter a keyword"
                                          value={keyWord}
                                          onChange={(e) => {
                                            setImagesSearchBool(false);
                                            setkeyWord(e.target.value);
                                          }}
                                          autoComplete="off"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-2 p-0">
                                      <button
                                        className="btn btn-default border border-dark"
                                        type="submit"
                                        style={{
                                          borderRadius: "8px",
                                          borderTopLeftRadius: "0",
                                          borderBottomLeftRadius: "0",
                                          height: "57px",
                                          padding: "0px",
                                          background: "#1cb7f2",
                                          width: "100%",
                                          marginTop: "23px",
                                        }}
                                      >
                                        <i
                                          className="fa fa-search"
                                          style={{ color: "white" }}
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row general_row">
                          {ImgAndAudio.map((value, index) => {
                            return (
                              <div
                                className="col-6 col-sm-4 col-md-3 my-2"
                                key={index}
                                style={{ position: "relative" }}
                              >
                                <img
                                  src={
                                    value.thumbnail.length > 0
                                      ? value.thumbnail
                                      : value.url
                                  }
                                  alt=""
                                  style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "150px",
                                    border: `${imgSrc.source === value.url
                                      ? "2px solid #1cb7f2"
                                      : "none"
                                      }`,
                                  }}
                                  className={`img-thumbnail search_img`}
                                  onClick={() =>
                                    setImgSrc({
                                      imgName: tabName.imgName,
                                      source: value.url,
                                    })
                                  }
                                />
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "15px",
                                    left: "25px",
                                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                                    color: "black",
                                    padding: "3px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    fontFamily: "SourceSansPro",
                                    fontSize: "12px",
                                  }}
                                >
                                  Pixabay
                                </span>
                                {imgSrc.source === value.url ? (
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "20px",
                                      padding: "5px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      fontFamily: "SourceSansPro",
                                    }}
                                  >
                                    <img src="./assets/images/tik.png" alt="" />
                                  </span>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                        {loader ? (
                          <div className="row general_row d-flex justify-content-center">
                            <div className="col-3 my-2">
                              <Loader />
                            </div>
                          </div>
                        ) : null}
                        <div
                          className="row general_row button_more m-0"
                          style={{
                            position: "fixed",
                            top: "84.1vh",
                            left: "50%",
                            transform:"translateX(-50%)",
                            minWidth: "75vw",
                            zIndex: "8",
                            background: "transparent",
                          }}
                        >
                          <div className="col-12 d-flex justify-content-center">
                            {ImgAndAudio.length ? (
                              <button
                                className="btn btn-3 accnt-mngmt-btn default-h px-2"
                                style={{ background: "#1cb7f2 !important" }}
                                onClick={() => {
                                  setLogoUrl(
                                    Object.keys(imgSrc).length === 0 &&
                                      imgSrc.constructor === Object
                                      ? ""
                                      : imgSrc.source
                                  );
                                  // changeImages(imgSrc.imgName, imgSrc.source);
                                  openCloseFunction((value) => !value);
                                }}
                                disabled={
                                  Object.keys(imgSrc).length === 0 &&
                                    imgSrc.constructor === Object
                                    ? true
                                    : false
                                }
                              >
                                Use This Image
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {/* Your Section */}
                      <div id="your" className="container tab-pane">
                        <br />

                        <div className="row general_row d-flex justify-content-center search-row">
                          <div className="col-11 col-md-9 search-area">
                            <div
                              className="row d-flex justify-content-around align-items-center py-2"
                              style={{ height: "100%" }}
                            >
                              <div
                                className="col-11 p-0"
                                style={{
                                  height: "75%",
                                  width: "100%",
                                  backgroundColor: "#363839",
                                  border: "2px white dashed",
                                }}
                              >
                                <form
                                  onSubmit={(e) => e.target.preventDefault()}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div className="upload-btn-wrapper">
                                    <div>
                                      <button
                                      className="small-fs"
                                        style={{
                                          backgroundColor: "#363839",
                                          color: "white",
                                          border: "none",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        {Object.keys(selectedFile).length ===
                                          0 &&
                                          selectedFile.constructor === Object
                                          ? "Upload File (JPG, PNG and JPEG upto 5MB & 500 x 500 pixels recommended)"
                                          : selectedFile.name}
                                      </button>
                                      <i
                                        className="fa fa-cloud-upload"
                                        aria-hidden="true"
                                        style={{
                                          display: "block",
                                          color: "white",
                                          fontSize: "1.9rem",
                                          paddingTop: "10px",
                                        }}
                                      ></i>
                                    </div>
                                    <input
                                      type="file"
                                      className="custom-file-input"
                                      id="customFile"
                                      name="file"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        padding: "0",
                                        cursor: "pointer",
                                      }}
                                      onChange={(e) => {
                                        uploadLogo(e);
                                      }}
                                    />
                                    {/* <label
                                      className="custom-file-label"
                                      htmlFor="customFile"
                                    >
                                      {Object.keys(selectedFile).length === 0 &&
                                      selectedFile.constructor === Object
                                        ? "Choose File"
                                        : selectedFile.name}
                                    </label> */}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row general_row">
                          {uploadLoader ? (
                            <div
                              className="col-3 my-2"
                              style={{ position: "relative" }}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center w-100"
                                style={{ height: "150px" }}
                              >
                                <Loader />
                              </div>
                            </div>
                          ) : null}
                          {uploadLogoData
                            ? uploadLogoData.map((value, index) => {
                              return (
                                <div
                                  className="col-6 col-sm-4 col-md-3 my-2"
                                  key={index}
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src={value.logo_url}
                                    alt=""
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "150px",
                                      border: `${logoSrc === value.logo_url
                                        ? "2px solid #1cb7f2"
                                        : "none"
                                        }`,
                                    }}
                                    className={`img-thumbnail search_img`}
                                    onClick={() => {
                                      setLogoSrc(value.logo_url);
                                      setDeleteId(value.id);
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "15px",
                                      left: "25px",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                      color: "black",
                                      padding: "3px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      fontFamily: "SourceSansPro",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Custom
                                  </span>
                                  {logoSrc === value.logo_url ? (
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        padding: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        fontFamily: "SourceSansPro",
                                      }}
                                    >
                                      <img
                                        src="./assets/images/tik.png"
                                        alt=""
                                      />
                                    </span>
                                  ) : null}
                                </div>
                              );
                            })
                            : null}
                        </div>

                        <div
                          className="row general_row m-0"
                          style={{
                            position: "fixed",
                            top: "84.1vh",
                            left: "50%",
                            transform:"translateX(-50%)",
                            minWidth: "75vw",
                            zIndex: "8",
                            background: "transparent",
                          }}
                        >
                          {uploadLogoData.length <= 0 ? null : (
                            <>
                              <div className="col-6 d-flex justify-content-end">
                                <button
                                  className="btn btn-2 accnt-mngmt-btn default-h mr-xl-2"
                                  onClick={() => {
                                    removeAlert();
                                    setDeleteData({
                                      type: "logo",
                                      id: deleteId,
                                    });
                                    setModal({ isOpen: true });
                                  }}
                                  disabled={
                                    Object.keys(logoSrc).length === 0 &&
                                      logoSrc.constructor === Object
                                      ? true
                                      : false
                                  }
                                >
                                  Delete
                                </button>
                              </div>
                              <div className="col-6 d-flex justify-content-start p-0">
                                <button
                                  type="button"
                                  className="btn btn-3 accnt-mngmt-btn default-h"
                                  style={{ background: "#1cb7f2 !important" }}
                                  onClick={() => {
                                    setLogoUrl(
                                      Object.keys(logoSrc).length === 0 &&
                                        logoSrc.constructor === Object
                                        ? ""
                                        : logoSrc
                                    );
                                    openCloseFunction((value) => !value);
                                  }}
                                  disabled={
                                    Object.keys(logoSrc).length === 0 &&
                                      logoSrc.constructor === Object
                                      ? true
                                      : false
                                  }
                                >
                                  Use This Image
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {/* ***********************Show Audio ********************/}
                {tabName.first === "Audio Library" ? (
                  <>
                    {/* <!-- Tab panes --> */}
                    <div
                      className="tab-content"
                      id={
                        tabName.first === "Audio Library"
                          ? "audio_scroll"
                          : "tem"
                      }
                    >
                      {/* Library Section */}
                      <div id="library" className="container tab-pane active">
                        <br />
                        <div className="row general_row justify-content-center mb-5">
                          {audio.map((value, index) => {
                            return (
                              <div
                                className="col-6 col-sm-4 col-md-3 col-lg-2 mt-3"
                                key={index}
                                style={{
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: "120px",
                                    background: "#171717",
                                  }}
                                  className={`card img-thumbnail search_img mb-2`}
                                  onClick={() => setAudioSrc(value.url)}
                                >
                                  <div className="card-body d-flex flex-column justify-content-center align-items-center" title={value.title}>
                                    <img
                                      src={"./assets/images/play_1.svg"}
                                      alt=""
                                      style={{
                                        borderRadius: "50%",
                                        width: "25px",
                                        height: "25px",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (value.url === audioObject.src) {
                                          if (
                                            e.target.src ===
                                            `${frontURL}/assets/images/play_1.svg`
                                          ) {
                                            audioObject.play();
                                            e.target.src =
                                              "./assets/images/pause.svg";
                                          } else {
                                            e.target.src =
                                              "./assets/images/play_1.svg";
                                            audioObject.pause();
                                          }
                                          return;
                                        }
                                        if (
                                          !(
                                            Object.keys(audioEvent).length ===
                                            0 &&
                                            audioEvent.constructor === Object
                                          )
                                        ) {
                                          audioEvent.src =
                                            "./assets/images/play_1.svg";
                                        }
                                        setAudioEvent(e.target);
                                        playOrPauseAudio(new Audio(value.url));
                                        e.target.src =
                                          "./assets/images/pause.svg";
                                      }}
                                    />

                                    <div className="card-text text-white mt-2 page">
                                      {value.title.split(" ")[0]}
                                    </div>
                                  </div>
                                </div>
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "10px",
                                    left: "25px",
                                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                                    color: "black",
                                    padding: "3px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    fontFamily: "SourceSansPro",
                                    fontSize: "12px",
                                  }}
                                >
                                  {libraryAudioDuration[index]} s
                                </span>
                                {audioSrc === value.url ? (
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "20px",
                                      padding: "5px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      fontFamily: "SourceSansPro",
                                    }}
                                  >
                                    <img src="./assets/images/tik.png" alt="" />
                                  </span>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                        {loader ? (
                          <div className="row general_row d-flex justify-content-center">
                            <div className="col-3 my-2">
                              <Loader />
                            </div>
                          </div>
                        ) : null}

                        <div
                          className="row  general_row button_more m-0"
                          style={{
                            position: "fixed",
                            top: "84.1vh",
                            left: "50%",
                            transform:"translateX(-50%)",
                            minWidth: "75vw",
                            zIndex: "8",
                            background: "transparent",
                          }}
                        >
                          <div className="col-12 d-flex justify-content-center">
                            <button
                              className="btn btn-3 accnt-mngmt-btn default-h"
                              style={{ background: "#1cb7f2 !important" }}
                              onClick={() => {
                                setAudioUrl(audioSrc);
                                openCloseFunction((value) => !value);
                                if (
                                  !(
                                    Object.keys(audioObject).length === 0 &&
                                    audioObject.constructor === Object
                                  )
                                ) {
                                  audioObject.pause();
                                }
                                setAudioObject({});
                              }}
                              disabled={audioSrc === "" ? true : false}
                            >
                              Use This Audio
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Your Section */}
                      <div id="your" className="container tab-pane fade">
                        <br />{" "}
                        <div className="row general_row d-flex justify-content-center search-row">
                          <div className="col-9 search-area">
                            <div
                              className="row d-flex justify-content-around align-items-center"
                              style={{ height: "100%" }}
                            >
                              <div
                                className="col-11 p-0"
                                style={{
                                  height: "75%",
                                  width: "100%",
                                  backgroundColor: "#363839",
                                  border: "2px white dashed",
                                }}
                              >
                                <form
                                  onSubmit={(e) => e.target.preventDefault()}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div className="upload-btn-wrapper">
                                    <div>
                                      <button
                                        style={{
                                          backgroundColor: "#363839",
                                          color: "white",
                                          border: "none",
                                          fontSize: "1rem",
                                        }}
                                        onClick={handleButtonClick}
                                      >
                                        {Object.keys(selectedFile).length ===
                                          0 &&
                                          selectedFile.constructor === Object
                                          ? "Upload File (MP3 upto 5MB is allowed)"
                                          : selectedFile.name}
                                      </button>
                                      <i
                                        className="fa fa-cloud-upload"
                                        aria-hidden="true"
                                        style={{
                                          display: "block",
                                          color: "white",
                                          fontSize: "1.7rem",
                                          paddingTop: "10px",
                                        }}
                                      ></i>
                                    </div>
                                    <input
                                      type="file"
                                      className="custom-file-input"
                                      id="customFile"
                                      name="file"
                                      ref={fileInputRef}
                                      accept=".mp3"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        padding: "0",
                                        cursor: "pointer",
                                      }}
                                      onChange={(e) => {
                                        uploadAudio(e);
                                      }}
                                    />

                                    {/* <label
                                      className="custom-file-label"
                                      htmlFor="customFile"
                                    >
                                      {Object.keys(selectedFile).length === 0 &&
                                      selectedFile.constructor === Object
                                        ? "Choose File"
                                        : selectedFile.name}
                                    </label> */}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row general_row justify-content-center">
                          {uploadLoader ? (
                            <div
                              className="col-2 mt-3"
                              style={{
                                position: "relative",
                              }}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center w-100"
                                style={{ height: "120px" }}
                              >
                                <Loader />
                              </div>
                            </div>
                          ) : null}
                          {uploadAudioData
                            ? uploadAudioData.map((value, index) => {
                              return (
                                <div
                                  className="col-6 col-sm-4 col-md-3 col-lg-2 mt-3"
                                  key={value.id}
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "120px",
                                      background: "#171717",
                                    }}
                                    className={`card img-thumbnail search_img mb-2 `}
                                    onClick={() => {
                                      setAudioSrc(value.audio_url);
                                      setDeleteId(value.id);
                                    }}
                                  >
                                    <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                      <img
                                        src={"./assets/images/play_1.svg"}
                                        alt=""
                                        style={{
                                          borderRadius: "50%",
                                          width: "25px",
                                          height: "25px",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          if (
                                            value.audio_url ===
                                            audioObject.src
                                          ) {
                                            if (
                                              e.target.src ===
                                              `${frontURL}/assets/images/play_1.svg`
                                            ) {
                                              audioObject.play();
                                              e.target.src =
                                                "./assets/images/pause.svg";
                                            } else {
                                              e.target.src =
                                                "./assets/images/play_1.svg";
                                              audioObject.pause();
                                            }
                                            return;
                                          }
                                          if (
                                            !(
                                              Object.keys(audioEvent)
                                                .length === 0 &&
                                              audioEvent.constructor ===
                                              Object
                                            )
                                          ) {
                                            audioEvent.src =
                                              "./assets/images/play_1.svg";
                                          }
                                          setAudioEvent(e.target);
                                          playOrPauseAudio(
                                            new Audio(value.audio_url)
                                          );
                                          e.target.src =
                                            "./assets/images/pause.svg";
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      left: "25px",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                      color: "black",
                                      padding: "3px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      fontFamily: "SourceSansPro",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {yourAudioDuration[index]} s
                                  </span>
                                  {audioSrc === value.audio_url ? (
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "20px",
                                        padding: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        fontFamily: "SourceSansPro",
                                      }}
                                    >
                                      <img
                                        src="./assets/images/tik.png"
                                        alt=""
                                      />
                                    </span>
                                  ) : null}
                                </div>
                              );
                            })
                            : null}
                        </div>
                        <div
                          className="row general_row m-0"
                          style={{
                            position: "fixed",
                            top: "84.1vh",
                            left: "50%",
                            transform:"translateX(-50%)",
                            minWidth: "75vw",
                            zIndex: "8",
                            background: "transparent",
                          }}
                        >
                          {uploadAudioData.length <= 0 ? null : (
                            <>
                              <div className="col-6 d-flex justify-content-end">
                                <button
                                  className="btn btn-2 accnt-mngmt-btn default-h mr-xl-2"
                                  onClick={() => {
                                    removeAlert();
                                    setDeleteData({
                                      type: "audio",
                                      id: deleteId,
                                    });
                                    setModal({ isOpen: true });
                                  }}
                                  disabled={audioSrc === "" ? true : false}
                                >
                                  Delete
                                </button>
                              </div>
                              <div className="col-6 d-flex justify-content-start">
                                <button
                                  type="button"
                                  className="btn btn-3 accnt-mngmt-btn default-h"
                                  style={{ background: "#1cb7f2 !important" }}
                                  onClick={() => {
                                    setAudioUrl(audioSrc);
                                    openCloseFunction((value) => !value);
                                    if (
                                      !(
                                        Object.keys(audioObject).length === 0 &&
                                        audioObject.constructor === Object
                                      )
                                    ) {
                                      audioObject.pause();
                                    }
                                    setAudioObject({});
                                  }}
                                  disabled={audioSrc === "" ? true : false}
                                >
                                  Use This Audio
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      {/* Upload Section */}
                      <div id="upload" className="container tab-pane fade">
                        <br />{" "}
                        <div className="row general_row d-flex justify-content-center py-2">
                          <div className="col-11 search-area">
                            {" "}
                            <div className="row d-flex justify-content-around align-items-center">
                              <div className="col-11 p-0">
                                <form onSubmit={(e) => e.preventDefault()}>
                                  <div className="row justify-content-center">
                                    <div className="col col-lg-4 col-11 p-1">
                                      <div className="form-group form-group-mb">
                                        <label className="input-label">
                                          Name{" "}
                                        </label>
                                        <input
                                          className="form-control input-dark-accnt personal-info  w-100"
                                          type="text"
                                          name="firstName"
                                          placeholder="Name"
                                          value={ttsName}
                                          onChange={(e) =>
                                            setTtsName(e.target.value)
                                          }
                                          required
                                          autoComplete="off"
                                        />
                                      </div>
                                    </div>
                                    <div className="col col-lg-4 col-11 p-1">
                                      <div className="form-group form-group-mb">
                                        <label className="input-label">
                                          Language{" "}
                                        </label>
                                        <select
                                          className="form-control input-account-type p-accnt"
                                          id="Account_selector"
                                          name="language"
                                          value={ttsLangVoice.language}
                                          onChange={(e) => {
                                            ttsUpdation(e);
                                          }}
                                        >
                                          <option
                                            value="-Select-"
                                            id="yt_video"
                                          >
                                            -Select-
                                          </option>
                                          {tts.length > 0
                                            ? tts.map((ttsVal) => {
                                              return (
                                                <option
                                                  key={ttsVal.id}
                                                  value={ttsVal.language_code}
                                                >
                                                  {ttsVal.name}
                                                </option>
                                              );
                                            })
                                            : null}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col col-lg-4 col-11 p-1">
                                      <div className="form-group form-group-mb">
                                        <label className="input-label">
                                          Voice{" "}
                                        </label>
                                        <select
                                          className="form-control input-account-type p-accnt"
                                          id="Account_selector"
                                          name="voice"
                                          value={ttsLangVoice.voice}
                                          onChange={(e) => {
                                            ttsUpdation(e);
                                          }}
                                        >
                                          <option
                                            value="-Select-"
                                            id="yt_video"
                                          >
                                            -Select-
                                          </option>
                                          {ttsVoice.length > 0
                                            ? ttsVoice.map((voice) => {
                                              return (
                                                <option
                                                  value={voice.voice_id}
                                                  key={voice.id}
                                                >
                                                  {voice.voice_id}(
                                                  {voice.gender})
                                                </option>
                                              );
                                            })
                                            : null}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col col-11 p-1">
                                      <div className="form-group form-group-mb">
                                        <textarea
                                          className="form-control input-dark-accnt personal-info"
                                          type="text"
                                          name="text"
                                          placeholder="eg. hey, check out our premium collection today"
                                          value={ttsLangVoice.text}
                                          onChange={(e) => {
                                            ttsUpdation(e);
                                          }}
                                          autoComplete="off"
                                          required
                                          style={{
                                            padding: "10px",
                                            height: "8rem",
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col col-11 p-2">
                                      <div className="row justify-content-end">
                                        <div className="col col-3 d-flex justify-content-end pr-1">
                                          <button
                                            className="btn btn-2 accnt-mngmt-btn default-h mr-xl-2"
                                            onClick={() => {
                                              generate();
                                            }}
                                            disabled={
                                              ttsLangVoice.language !==
                                                "-Select-" &&
                                                ttsLangVoice.voice !==
                                                "-Select-" &&
                                                ttsLangVoice.text !== "" &&
                                                ttsName !== ""
                                                ? false
                                                : true
                                            }
                                          >
                                            Generate
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row general_row justify-content-center">
                          {ttsLoader ? (
                            <div
                              className="col-2 mt-3"
                              style={{
                                position: "relative",
                              }}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center w-100"
                                style={{ height: "120px" }}
                              >
                                <Loader />
                              </div>
                            </div>
                          ) : null}
                          {ttsAudioUrl
                            ? ttsAudioUrl.map((value, index) => {
                              return (
                                <div
                                  className="col-6 col-sm-4 col-md-3 col-lg-2 mt-3"
                                  key={index}
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "120px",
                                      background: "#171717",
                                    }}
                                    className={`card img-thumbnail search_img mb-2 `}
                                    onClick={() => {
                                      setAudioSrc(value.audio_url);
                                    }}
                                  >
                                    <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                      <img
                                        src={"./assets/images/play_1.svg"}
                                        alt=""
                                        style={{
                                          borderRadius: "50%",
                                          width: "25px",
                                          height: "25px",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          if (
                                            value.audio_url ===
                                            audioObject.src
                                          ) {
                                            if (
                                              e.target.src ===
                                              `${frontURL}/assets/images/play_1.svg`
                                            ) {
                                              audioObject.play();
                                              e.target.src =
                                                "./assets/images/pause.svg";
                                            } else {
                                              e.target.src =
                                                "./assets/images/play_1.svg";
                                              audioObject.pause();
                                            }
                                            return;
                                          }
                                          if (
                                            !(
                                              Object.keys(audioEvent)
                                                .length === 0 &&
                                              audioEvent.constructor ===
                                              Object
                                            )
                                          ) {
                                            audioEvent.src =
                                              "./assets/images/play_1.svg";
                                          }
                                          setAudioEvent(e.target);
                                          playOrPauseAudio(
                                            new Audio(value.audio_url)
                                          );
                                          e.target.src =
                                            "./assets/images/pause.svg";
                                        }}
                                      />
                                      <div className="card-text text-white mt-2 page">
                                        {value.name}
                                      </div>
                                    </div>
                                  </div>
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      left: "25px",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                      color: "black",
                                      padding: "3px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      fontFamily: "SourceSansPro",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {ttsAudioDuration[index]} s
                                  </span>
                                  {audioSrc === value.audio_url ? (
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "20px",
                                        padding: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        fontFamily: "SourceSansPro",
                                      }}
                                    >
                                      <img
                                        src="./assets/images/tik.png"
                                        alt=""
                                      />
                                    </span>
                                  ) : null}
                                </div>
                              );
                            })
                            : null}
                        </div>
                        <div
                          className="row general_row m-0"
                          style={{
                            position: "fixed",
                            top: "84.1vh",
                            left: "50%",
                            transform:"translateX(-50%)",
                            minWidth: "75vw",
                            zIndex: "8",
                            background: "transparent",
                          }}
                        >
                          {ttsAudioUrl.length <= 0 ? null : (
                            <>
                              <div className="col-6 d-flex justify-content-end">
                                <button
                                  className="btn btn-2 accnt-mngmt-btn default-h mr-xl-2"
                                  onClick={() => {
                                    removeAlert();
                                    setDeleteData({
                                      type: "tts",
                                      url: audioSrc,
                                    });
                                    setModal({ isOpen: true });
                                  }}
                                  disabled={audioSrc === "" ? true : false}
                                >
                                  Delete
                                </button>
                              </div>
                              <div className="col-6 d-flex justify-content-start">
                                <button
                                  type="button"
                                  className="btn btn-3 accnt-mngmt-btn default-h"
                                  style={{ background: "#1cb7f2 !important" }}
                                  onClick={() => {
                                    setAudioUrl(audioSrc);
                                    openCloseFunction((value) => !value);
                                    if (
                                      !(
                                        Object.keys(audioObject).length === 0 &&
                                        audioObject.constructor === Object
                                      )
                                    ) {
                                      audioObject.pause();
                                    }
                                    setAudioObject({});
                                  }}
                                  disabled={audioSrc === "" ? true : false}
                                >
                                  Use This Audio
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {/* ***********************Show Images ********************/}
                {tabName.first === "Images Library" ? (
                  <>
                    {/* <!-- Tab panes --> */}
                    <div
                      className="tab-content"
                      id={
                        tabName.first === "Images Library"
                          ? "images_scroll"
                          : "tem_img"
                      }
                    >
                      {/* Library Section */}
                      <div id="library" className="container tab-pane active">
                        <br />
                        <div className="row general_row d-flex justify-content-center search-row">
                          <div className="col-11 col-md-9 search-area">
                            <div
                              className="row d-flex justify-content-around align-items-center"
                              style={{ height: "100%" }}
                            >
                              <div className="col-10 p-0" style={{marginBottom:"20px"}}>
                                <form onSubmit={searchImagesAndAudio}>
                                  <div className="row ">
                                    <div className="col-10 p-0">
                                      <div className="form-group m-0">
                                        <label className="input-label">
                                          Search for media{" "}
                                        </label>
                                        <input
                                          className="form-control input-dark-accnt personal-info input-radius"
                                          type="text"
                                          name="search"
                                          placeholder="Enter a keyword"
                                          value={keyWord}
                                          onChange={(e) => {
                                            setImagesSearchBool(false);
                                            setkeyWord(e.target.value);
                                          }}
                                          autoComplete="off"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-2 p-0">
                                      <button
                                        className="btn btn-default border border-dark"
                                        type="submit"
                                        style={{
                                          borderRadius: "0px 8px 8px 0px",
                                          height: "57px",
                                          padding: "0px",
                                          background: "#1cb7f2",
                                          width: "100%",
                                          marginTop: "23px",
                                        }}
                                      >
                                        <i
                                          className="fa fa-search"
                                          style={{ color: "white" }}
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>

                              <div className="col-11">
                                <form>
                                  <div className="custom-control custom-radio custom-control-inline mb-2">
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      id="customRadio"
                                      name="imageAndVideo"
                                      value="image"
                                      checked={
                                        imagesOrVideo === "image" ? true : false
                                      }
                                      onChange={(e) => {
                                        setImagesOrVideo(e.target.value);
                                        setkeyWord("");
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      style={{ paddingTop: "4px" }}
                                      htmlFor="customRadio"
                                    >
                                      Images
                                    </label>
                                  </div>
                                  <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      id="customRadio2"
                                      name="imageAndVideo"
                                      value="video"
                                      checked={
                                        imagesOrVideo === "video" ? true : false
                                      }
                                      onChange={(e) => {
                                        setImagesOrVideo(e.target.value);
                                        setkeyWord("");
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customRadio2"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      Videos
                                    </label>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row general_row justify-content-center">
                          {imagesOrVideo === "image" ? (
                            ImgAndAudio.map((value, index) => {
                              return (
                                <div
                                  className="col-6 col-sm-4 col-md-3 my-2"
                                  key={index}
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src={
                                      value.thumbnail.length > 0
                                        ? value.thumbnail
                                        : value.url
                                    }
                                    alt=""
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "150px",
                                      border: `${imgSrc.source === value.url
                                        ? "2px solid #1cb7f2"
                                        : "none"
                                        }`,
                                    }}
                                    className={`img-thumbnail search_img`}
                                    onClick={() =>
                                      setImgSrc({
                                        imgName: tabName.imgName,
                                        source: value.url,
                                        thumb: value.url.includes(".mp4")
                                          ? value.thumbnail
                                          : "",
                                      })
                                    }
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "15px",
                                      left: "25px",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                      color: "black",
                                      padding: "3px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      fontFamily: "SourceSansPro",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Pixabay
                                  </span>
                                  {imgSrc.source === value.url ? (
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        padding: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        fontFamily: "SourceSansPro",
                                      }}
                                    >
                                      <img
                                        src="./assets/images/tik.png"
                                        alt=""
                                      />
                                    </span>
                                  ) : null}
                                </div>
                              );
                            })
                          ) : imagesOrVideo === "video" ? (
                            videoLibrary.map((value, index) => {
                              return (
                                <div
                                  className="col-6 col-sm-4 col-md-3 my-2 p-0 px-2"
                                  style={{
                                    position: "relative",
                                    cursor: "pointer",
                                  }}
                                  key={index}
                                  onClick={() =>
                                    setImgSrc({
                                      imgName: tabName.imgName,
                                      source: value.url,
                                      thumb: value.url.includes(".mp4")
                                        ? value.thumbnail
                                        : "",
                                    })
                                  }
                                >
                                  <HoverVideoPlayer
                                    videoSrc={value.url}
                                    videoStyle={{
                                      margin: "0px",
                                      padding: "0px",
                                      width: "100%",
                                      height: "150px",
                                    }}
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                    }}
                                    unloadVideoOnPaused
                                    preload="metadata"
                                    pausedOverlay={
                                      <div
                                        style={{
                                          position: "relative",
                                          zIndex: "5",
                                        }}
                                      >
                                        <img
                                          src={value.thumbnail}
                                          style={{
                                            objectFit: "cover",
                                            width: "100%",
                                            height: "150px",
                                            cursor: "pointer",
                                          }}
                                          alt=""
                                        />
                                        <span
                                          style={{
                                            position: "absolute",
                                            top: "15px",
                                            left: "10px",
                                            backgroundColor:
                                              "rgba(255, 255, 255, 0.8)",
                                            color: "black",
                                            padding: "3px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "5px",
                                            fontFamily: "SourceSansPro",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {value.duration !== 0 ? (
                                            value.duration + "s"
                                          ) : (
                                            <i
                                              class="fa fa-video-camera"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </span>
                                        {imgSrc.source === value.url ? (
                                          <span
                                            style={{
                                              position: "absolute",
                                              top: "10px",
                                              right: "5px",
                                              padding: "5px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              borderRadius: "5px",
                                              fontFamily: "SourceSansPro",
                                            }}
                                          >
                                            <img
                                              src="./assets/images/tik.png"
                                              alt=""
                                            />
                                          </span>
                                        ) : null}
                                      </div>
                                    }
                                    loadingOverlay={
                                      <div
                                        className="loading-spinner-overlay d-flex justify-content-center align-items-center"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      >
                                        <div
                                          className="spinner-border text-primary"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                    }
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "15px",
                                      left: "25px",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                      color: "black",
                                      padding: "3px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      fontFamily: "SourceSansPro",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {value.duration} s
                                  </span>
                                  {imgSrc.source === value.url ? (
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        padding: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        fontFamily: "SourceSansPro",
                                      }}
                                    >
                                      <img
                                        src="./assets/images/tik.png"
                                        alt=""
                                      />
                                    </span>
                                  ) : null}
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                        {loader ? (
                          <div className="row general_row d-flex justify-content-center">
                            <div className="col-3 my-2">
                              <Loader />
                            </div>
                          </div>
                        ) : null}
                        <div
                          className="row general_row button_more m-0"
                          style={{
                            position: "fixed",
                            top: "84.1vh",
                            left: "50%",
                            transform:"translateX(-50%)",
                            minWidth: "75vw",
                            zIndex: "8",
                            background: "transparent",
                          }}
                        >
                          <div className="col-12 d-flex justify-content-center">
                            {(ImgAndAudio.length > 0 &&
                              imagesOrVideo === "image") ||
                              (videoLibrary.length > 0 &&
                                imagesOrVideo === "video") ? (
                              <button
                                className="btn btn-3 accnt-mngmt-btn default-h px-2"
                                style={{
                                  background: "#1cb7f2 !important",
                                }}
                                onClick={() => {
                                  changeImages(
                                    imgSrc.imgName,
                                    imgSrc.source,
                                    imgSrc.thumb
                                  );
                                  openCloseFunction((value) => !value);
                                }}
                                disabled={
                                  Object.keys(imgSrc).length === 0 &&
                                    imgSrc.constructor === Object
                                    ? true
                                    : false
                                }
                              >
                                {imagesOrVideo === "video"
                                  ? "Use This Video"
                                  : "Use This Image"}
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* Your Section */}
                      <div id="your" className="container tab-pane fade">
                        <br />
                        <div className="row general_row d-flex justify-content-center search-row">
                          <div className="col-9 search-area">
                            <div
                              className="row d-flex justify-content-around align-items-center py-2"
                              style={{ height: "100%" }}
                            >
                              <div
                                className="col-11 p-0"
                                style={{
                                  height: "75%",
                                  width: "100%",
                                  backgroundColor: "#363839",
                                  border: "2px white dashed",
                                }}
                              >
                                <form
                                  onSubmit={(e) => e.target.preventDefault()}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div className="upload-btn-wrapper">
                                    <div>
                                      <button
                                      className="small-fs"
                                        style={{
                                          backgroundColor: "#363839",
                                          color: "white",
                                          border: "none",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        {Object.keys(selectedFile).length ===
                                          0 &&
                                          selectedFile.constructor === Object
                                          ? "Upload File (JPG, PNG, JPEG upto 5MB & MP4 upto 100MB is allowed)"
                                          : selectedFile.name}
                                      </button>
                                      <i
                                        className="fa fa-cloud-upload"
                                        aria-hidden="true"
                                        style={{
                                          display: "block",
                                          color: "white",
                                          fontSize: "1.7rem",
                                          paddingTop: "10px",
                                        }}
                                      ></i>
                                    </div>
                                    <input
                                      type="file"
                                      className="custom-file-input"
                                      id="customFile"
                                      name="file"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        padding: "0",
                                        cursor: "pointer",
                                      }}
                                      onChange={(e) => {
                                        uploadImages(e);
                                      }}
                                    />

                                    {/* <label
                                      className="custom-file-label"
                                      htmlFor="customFile"
                                    >
                                      {Object.keys(selectedFile).length === 0 &&
                                      selectedFile.constructor === Object
                                        ? "Choose File"
                                        : selectedFile.name}
                                    </label> */}
                                  </div>
                                </form>

                                {/* <form  
                                onSubmit={(e) => e.target.preventDefault()}
                                 style={{ height: "100%",width:"100%",cursor:"pointer" }}>
                                  <div className="custom-file">
                                    <input
                                      type="file"
                                      className="custom-file-input"
                                      id="customFile"
                                      name="file"
                                      onChange={(e) => {
                                        uploadImages(e);
                                      }}
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="customFile"
                                    >
                                      {Object.keys(selectedFile).length === 0 &&
                                      selectedFile.constructor === Object
                                        ? "Choose File"
                                        : selectedFile.name}
                                    </label>
                                  </div>
                                </form> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row general_row">
                          {uploadLoader ? (
                            <div
                              className="col-3 my-2"
                              style={{ position: "relative" }}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center w-100"
                                style={{ height: "150px" }}
                              >
                                <Loader />
                              </div>
                            </div>
                          ) : null}
                          {uploadData
                            ? uploadData.map((value, index) => {
                              return (
                                <div
                                  className="col-6 col-sm-4 col-md-3 my-2"
                                  key={value.id}
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src={
                                      value.thumbnail.length > 0
                                        ? value.thumbnail
                                        : value.image_url
                                    }
                                    alt=""
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "150px",
                                      border: `${imgSrc.source === value.image_url
                                        ? "2px solid #1cb7f2"
                                        : "none"
                                        }`,
                                    }}
                                    className={`img-thumbnail search_img`}
                                    onClick={() => {
                                      setImgSrc({
                                        imgName: tabName.imgName,
                                        source: value.image_url,
                                        thumb: value.image_url.includes(
                                          ".mp4"
                                        )
                                          ? value.thumbnail
                                          : "",
                                      });
                                      setDeleteId(value.id);
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "15px",
                                      left: "25px",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                      color: "black",
                                      padding: "3px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      fontFamily: "SourceSansPro",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {value.image_url.includes(".mp4")
                                      ? "Video"
                                      : "Image"}
                                  </span>
                                  {imgSrc.source === value.image_url ? (
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        padding: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        fontFamily: "SourceSansPro",
                                      }}
                                    >
                                      <img
                                        src="./assets/images/tik.png"
                                        alt=""
                                      />
                                    </span>
                                  ) : null}
                                </div>
                              );
                            })
                            : null}
                        </div>

                        <div
                          className="row general_row align-items-center justify-content-center m-0"
                          style={{
                            position: "fixed",
                            top: "84.1vh",
                            left: "50%",
                            transform:"translateX(-50%)",
                            minWidth: "75vw",
                            zIndex: "8",
                            background: "transparent",
                          }}
                        >
                          {uploadData.length <= 0 ? null : (
                            <>
                              <div className="col-6 d-flex justify-content-end">
                                <button
                                  className="btn btn-2 accnt-mngmt-btn default-h mr-xl-2"
                                  onClick={() => {
                                    removeAlert();
                                    setDeleteData({
                                      type: "image",
                                      id: deleteId,
                                    });
                                    setModal({ isOpen: true });
                                  }}
                                  disabled={
                                    Object.keys(imgSrc).length === 0 &&
                                      imgSrc.constructor === Object
                                      ? true
                                      : false
                                  }
                                >
                                  Delete
                                </button>
                              </div>
                              <div className="col-6 d-flex justify-content-start">
                                { }
                                <button
                                  className="btn btn-3 accnt-mngmt-btn default-h"
                                  onClick={() => {
                                    changeImages(
                                      imgSrc.imgName,
                                      imgSrc.source,
                                      imgSrc.thumb
                                    );
                                    openCloseFunction((value) => !value);
                                  }}
                                  disabled={
                                    Object.keys(imgSrc).length === 0 &&
                                      imgSrc.constructor === Object
                                      ? true
                                      : false
                                  }
                                >
                                  Use This Image
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </EditingStyle>
          </div>
        </div>
      </div>

      {modal.isOpen ? (
        <ConfirmDelete close={close} confirm={deleteProjectByModal} />
      ) : null}
    </div>
  );
};

const EditingStyle = styled.div`
  .edit_part {
    width: 100%;
    margin: 0;
    height: 75vh;
  }

  .edit_tab {
    border-radius: 0;
    font-size: 1rem !important;
    border: none;
  }

  .edit_tab:hover {
    background-image: linear-gradient(
      150deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%
    );
    color: #fff !important;
  }

  li .active {
    background-image: linear-gradient(
      150deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%
    ) !important;
    color: #fff !important;
  }

  .tab-content {
    overflow-y: scroll;
    height: 59vh !important;
    overflow-x: hidden;
  }

  .search_img:hover {
    cursor: pointer;
    border: 3px solid #1cb7f2 !important;
  }

  .search-area {
    background: #171717 !important;
    height: 100% !important;
    border-radius: 5px;
  }
  .search-row {
    height: 9rem !important;
  }
  .page {
    position: relative;
    display: inline-block;
    font-weight: normal;
    font-size: 12px;
    width: 100px;
  }

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .upload-btn-wrapper .btn {
    background: #171717 !important;
    color: white;
    cursor: pointer;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::before,
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #1cb7f2;
    border-radius: 50%;
  }
`;

EditPopup.propTypes = {
  openCloseFunction: PropTypes.func.isRequired,
  tabName: PropTypes.object.isRequired,
  changeImages: PropTypes.func.isRequired,
  fetchLoadTemplate: PropTypes.func.isRequired,
  fetchUploadedAudio: PropTypes.func.isRequired,
  fetchUploadedLogo: PropTypes.func.isRequired,
  removeAlert: PropTypes.func.isRequired,
  getTtsData: PropTypes.func.isRequired,
  generateTts: PropTypes.func.isRequired,
  generateTtsAudio: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  loadingTemplate: state.loadingTemplate,
  ttsValue: state.ttsValue,
});

export default connect(mapStateToProps, {
  setAlert,
  fetchLoadTemplate,
  fetchUploadedAudio,
  fetchUploadedLogo,
  removeAlert,
  getTtsData,
  generateTts,
  generateTtsAudio,
})(EditPopup);
