import * as React from "react";
import Video from "./Video";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { appName } from "../../global/global";

export function RecentVideo() {
  const rebrand = useSelector(state => state.brandingdata.brandingdata)

  return (
    <div>
      <section id="project-section" className="bg-dark">
        <div className="container">
          <div className="row row-1 align-items-end homePro ">
            <div className="col colmn-1">
              <div className="title-area">
                <p
                  className="text-white text-left"
                  style={{ fontWeight: 600, fontSize: "16px" }}
                >
                  Recently Created Videos
                </p>
              </div>
            </div>
            <div className="col colmn-2 text-right">
              <Link
                className="view-all flex-elem justify-content-end font-weight-bold"
                to="/my-videos"
                style={{ fontWeight: 600, fontSize: "16px" }}
              >
                View All
                <span className="icon arrow pl-2" style={{ color: "#1cb7f2" }}>
                  <svg width="18px" height="18px" viewBox="0 0 28 28">
                    <use xlinkHref="./assets/images//right-arrow.svg#rght-arrow" />
                  </svg>
                </span>
              </Link>
            </div>
          </div>
          <div className="row row-2">
            <Video />
          </div>
        </div>
      </section>

      <div>
        <section className="vidSec">
          <div className="container">
            <div>
              <div className="vidSec-single">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="vidSec-txt">
                      <h2>Choose From 175 Professionally-Designed Video Templates</h2>
                      <p>No need to spend hours creating social media videos that hook your audience. In 3 clicks, {rebrand?.reseller?.app_name ? rebrand?.reseller?.app_name : appName} creates a unique video for you in minutes.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="vidSec-img"><img src={require('../../../src/assets/images/imac-1.png')} /></div>
                  </div>
                </div>
              </div>
              <div className="vidSec-single">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="vidSec-txt">
                      <h2>Choose from designed-for-you sleek video player templates</h2>
                      <p>Access a library of professionally-designed templates that make your videos pop. Choose from 100s of Premium, Normal, Intro & Outro video templates that get you and your clients views and engagement.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="vidSec-img"><img src={require('../../../src/assets/images/imac-2.png')} /></div>
                  </div>
                </div>
              </div>
              <div className="vidSec-single">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="vidSec-txt">
                      <h2>Unlimited Access To All-In-One Media Library</h2>
                      <p>Add sleek animations, unique fonts, videos, music, and images to spice up your video. Consistently upload videos that make your brand stand out.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="vidSec-img"><img src={require('../../../src/assets/images/imac-3.png')} /></div>
                  </div>
                </div>
              </div>
              <div className="vidSec-single">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="vidSec-txt">
                      <h2>Create Engaging Videos For Any Niche In Three Clicks</h2>
                      <p>Access a library of professionally-designed templates that make your videos pop. Choose from 100s of Premium, Normal, Intro & Outro video templates that get you and your clients views and engagement.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="vidSec-img"><img src={require('../../../src/assets/images/imac-4.png')} /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </div>
  );
}

export default RecentVideo;
