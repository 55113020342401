import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// Redux
import { connect, useSelector } from "react-redux";

const SupportSidenav = ({ support: { loading, support_articles } }) => {
  const [articles, setArticles] = useState([]);
  const history = useHistory();
  const rebrand = useSelector(state => state.brandingdata.brandingdata)
  const auth = useSelector(state => state.auth)
  useEffect(() => {
    if (support_articles.length > 0) {
      setArticles(loading || !support_articles ? [] : support_articles);
    }
  }, [loading, support_articles]);

  return (
    <div className="col colmn-2 col-lg-4 col-md-5 col-sm-11 col-12 mx-auto">
      <div id="lft-side-bar" className="bg-dark w-100 pb-4">
        <div className="search-bar w-100 mb-4" style={{ height: "98px" }}>
          <div className="img-wrapper mb-5">
            {+auth?.user?.isWl === 0 ?
              (
                <img
                  src="./assets/images/reelapps.png"
                  className="img-fluid"
                  alt="logo"
                  style={{ height: "54px", objectFit: "contain" }}
                />
              ) : (
                <img
                  src={rebrand?.reseller?.logo ? rebrand.reseller.logo : "./assets/images/video-reel.png"}
                  className="img-fluid"
                  alt="logo"
                  style={{ height: "54px", objectFit: "contain" }}
                />
              )


            }
          </div>
        </div>
        <div className="tpcs-list mb-4 pb-3">
          <h6 className="title mb-4">POPULAR TOPICS</h6>
          <ul className="list">
            {articles.map((article) => (
              <li className="list-item mb-3" key={article.id}>
                <a
                  href="!#"
                  className="d-flex"
                  onClick={(e) => {
                    e.preventDefault();
                    return history.push(
                      `/support-article?category=${article.id}`
                    );
                  }}
                >
                  <p className="tpc-item mb-0 text-white pr-2">
                    {article.name}
                  </p>
                  <span className="tpc-item-no flex-grow-0">
                    {article.item_count}
                  </span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        {/* <div className="tags-list">
          <h6 className="title mb-4">POPULAR TAGS</h6>
          <div className="tags-wrapper d-flex flex-wrap">
            <span className="tag">Bitcoin</span>
            <span className="tag">Blockchain</span>
            <span className="tag">Contracts</span>
            <span className="tag">CryptoCurrency</span>
            <span className="tag">ICO</span>
            <span className="tag">Ethereum</span>
            <span className="tag">Litecoin</span>
            <span className="tag">Trading</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  support: state.supportData,
});

SupportSidenav.propTypes = {
  support: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(SupportSidenav);
