import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { logout } from "../../actions/auth";
import PropTypes from "prop-types";
import { MdOutlineInbox } from "react-icons/md";
import defaultImg from '../../../src/assets/images/empty.png'

function Profile({ logout, user, loading }) {
  const [profile_pic, setProfilePic] = useState("");
  const [userId, setUserId] = useState("");
  const [is_client, setIsClient] = useState(false);
  const [is_team, setIsTeam] = useState(false);
  const [accountType, setAccountType] = useState([]);
  const [ioMember, setIoMember] = useState(false)
  // const mainIs = useSelector(state => state.auth.user.isWl)
  const [mainIs, setMainIs] = useState(false);
  useEffect(() => {
    if (user) {
      if (user.account_type.length === 1 && user.account_type[0] === "IO") {
        setIoMember(true)
      }
    }
  }, [user])
  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
      setIsTeam(loading || !user.is_team ? false : user.is_team);
      setMainIs(!loading && user.isWl);
    }
  }, [user, loading]);

  useEffect(() => {
    setProfilePic(loading || !user ? "" : `${user.profile_pic}`);
    setUserId(loading || !user ? "" : user.user_id);
  }, [user, loading]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  return (
    <>
      <li className="nav-item nav-item-7 dropdown">
        <Link
          id="avatar"
          className="nav-link text-white pr-0"
          to="#"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            className="img-fluid avatar-image rounded-circle"
            src={
              profile_pic ? profile_pic : defaultImg
            }
            alt="Avatar"
          />
          {/* <span className="avatar-notification" /> */}
        </Link>
        <div
          className="dropdown-menu dropdown-menu-arrow dropdown-scroll-height"
          aria-labelledby="navbarDropdown"
        >
          <Link className="dropdown-item" to="/profile">
            <i className="fa fa-user" aria-hidden="true"></i>
            <i className="pl-2" style={{ fontStyle: "normal" }}>
              My Account
            </i>
          </Link>
          {is_client === true || is_team === true ? null : (
            <Link className="dropdown-item" to="/privacy">
              <i className="fa fa-key" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Privacy Settings
              </i>
            </Link>
          )}


          {ioMember ? '' : <>
            {is_client === true || is_team === true ? null : (
              <Link className="dropdown-item" to="./account">
                <i className="fa fa-users" aria-hidden="true"></i>
                <i className="pl-2" style={{ fontStyle: "normal" }}>
                  Account Management
                </i>
              </Link>
            )}

            {/* {is_client === true || is_team === true ? null : accountType.includes(
              "business"
            ) || accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./fb-templates">
              <i className="fa fa-plus-square" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                Upgrades
              </i>
            </Link>
          ) : null} */}

            {mainIs === "0" &&
              (is_client === true ? null :
                <Link className="dropdown-item" to="./upgrades">
                  <i className="fa fa-plus-square" aria-hidden="true"></i>
                  <i className="pl-2" style={{ fontStyle: "normal" }}>
                    Upgrades
                  </i>
                </Link>
              )
            }
          </>
          }



          {is_client === true ? null : accountType.includes("unlimited") ||
            accountType.includes("lite") ||
            accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./reel-merge">
              <i className="fa fa-files-o" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                ReelMerge
              </i>
            </Link>
          ) : null}

          {is_client === true ? null : accountType.includes("unlimited") ||
            accountType.includes("lite") ||
            accountType.includes("bundle") ? (
            <Link className="dropdown-item" to="./my-merge-video">
              <i className="fa fa-user-plus" aria-hidden="true"></i>
              <i className="pl-2" style={{ fontStyle: "normal" }}>
                My Merge Videos
              </i>
            </Link>
          ) : null}



          {mainIs === "0" &&
            (is_client === true ? null :
              accountType.includes("bundle") && (
                <Link className="dropdown-item" to="/white-label">
                  {/* <i className="fa fa-user" aria-hidden="true"></i> */}
                  <MdOutlineInbox fontSize={15} />
                  <span className="pl-2" style={{ fontStyle: "normal", fontSize: "12px", fontWeight: "500", color: "#000" }}>
                    White Label
                  </span>
                </Link>
              )
            )
          }



          <Link className="dropdown-item" to="./help-and-support">
            <i className="fa fa-headphones" aria-hidden="true"></i>
            <i className="pl-2" style={{ fontStyle: "normal" }}>
              Help & Support
            </i>
          </Link>

          <div className="dropdown-divider" />
          <Link
            to="!#"
            className="dropdown-item"
            onClick={(e) => {
              e.preventDefault();
              logout(userId, user);
              return <Redirect to="/" />;
            }}
          >
            <div className="d-flex justify-content-between">
              <div>
                <i className="fa fa-sign-out" aria-hidden="true"></i>
                <i className="pl-2" style={{ fontStyle: "normal" }}>
                  Sign Out
                </i>
              </div>
              <div className="pr-3">
                <img
                  src="./assets/images/right-arrow.svg"
                  width="12px"
                  height="12px"
                  alt=""
                />
              </div>
            </div>
          </Link>
        </div>
      </li>
    </>
  );
}

Profile.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { logout })(Profile);
