import React from "react";
import styled from "styled-components";
import BusinessNav from "../businessNav/BusinessNav";
import { Helmet } from "react-helmet";
import TitleBar from "../Common/TitleBar";

const Upgrades = () => {
  return (
    <FbStyle>

      <TitleBar title="Upgrade" />
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <BusinessNav />
              <div className="col-9 mb-5 ">
                <h6
                  className="text-white  mb-0 mt-5 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Upgrades
                </h6>

                <div className="row bg-table justify-content-center align-items-center">
                  <div className="col-8">
                    <div className="card fb-card border-0">
                      <div
                        className="card-header header-bg"
                        style={{ background: "#1cb7f2" }}
                      >
                        <h5 className="text-light">Welcome!</h5>
                      </div>
                      <div className="card-body">
                        To access your upgrades, click any of the available
                        options in left side menu.
                      </div>
                      <div className="card-footer p-0"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </FbStyle>
  );
};

const FbStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }
  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default Upgrades;
