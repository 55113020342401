import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ConfirmDelete from "../confirmdelete/ConfirmDelete";
import { connect } from "react-redux";
import {
  deleteClientTeamAccount,
  editClientTeamAccount,
} from "../../actions/accountManagement";
import { setLoader } from "../../actions/loader";
import ResetPassword from "./ResetPassword";
import { removeAlert } from "../../actions/alert";

const TeamMemberManagement = ({
  teamMember,
  userGenId,
  deleteClientTeamAccount,
  editClientTeamAccount,
  loader,
  setLoader,
  removeAlert,
  showAlert,
}) => {
  const [teamMembersInfo, setTeamMembersInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const [modal, setModal] = useState({
    isOpen: false,
  });

  const [passwordModal, setPasswordModal] = useState({
    open: false,
    appName: "team",
  });

  const [check, setCheck] = useState(false);

  useEffect(() => {
    setTeamMembersInfo({
      password: "",
      firstName: teamMember.name ? teamMember.name : "",

      email: teamMember.email ? teamMember.email : "",
    });
  }, [teamMember]);

  const editTeamInfo = (e) => {
    setTeamMembersInfo({
      ...teamMembersInfo,
      [e.target.name]: e.target.value,
    });
  };

  const close = () => {
    setModal({ isOpen: false });
  };

  // Opening password modal
  const handleOpen = () => {
    removeAlert();
    setPasswordModal({
      ...modal,
      open: true,
      appName: "team",
    });
  };

  // Closing password modal
  const handleClose = () => {
    setPasswordModal({
      ...modal,
      open: false,
    });
  };

  // Delete Account
  const deleteConfimAccount = async () => {
    const data = {
      user_id: userGenId,
      account_id: teamMember.id,
      type: "team",
    };

    await deleteClientTeamAccount(data);

    close();
  };

  // Update Account
  const updateTeamAccount = async () => {
    setCheck(true);
    setLoader();
    showAlert(true);
    const data = {
      id: teamMember.id,
      email: teamMembersInfo.email,
      password: teamMembersInfo.password,
      Firstname: teamMembersInfo.firstName.split(" ")[0],
      Lastname: teamMembersInfo.firstName.split(" ").slice(1)
        ? teamMembersInfo.firstName.split(" ").slice(1).join(" ")
        : "",
      type: "team",
    };

    await editClientTeamAccount(data, userGenId);

    setCheck(false);
  };

  return (
    <>
      <tr className="accordion-toggle collapsed" id="accordion1">
        <td className="td-br">{teamMember.name}</td>
        <td className="text-overflow td-br">{teamMember.email}</td>
        <td className="td-br">Team Member</td>
        <td className="td-br">{teamMember.created}</td>
        <td className="expand-button d-flex justify-content-center" style={{ minWidth: "100px", gap: "5px" }}>
          <a
            className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1"
            title="Edit"
            data-toggle="collapse"
            data-parent="#accordion1"
            href={`#${teamMember.id}teamMember`}
          >
            <svg width="20px" height="20px" viewBox=" 0 0 20 20">
              <use xlinkHref="./assets/images/edit.svg#edit-ico" />{" "}
            </svg>
          </a>
          <Link
            to="#"
            className="action-icon lock-icon remove mb-1 pl-xl-2  mt-md-1"
            data-placement="top"
            data-toggle="tooltip"
            title="Reset Password"
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 31.7 36"
              data-toggle="modal"
              data-target="#reset-password"
              onClick={() => handleOpen()}
            >
              <use xlinkHref="./assets/images/lock.svg#ico-lock" />
            </svg>
          </Link>
          <Link
            to="#"
            className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1"
            data-toggle="modal"
            // style={{marginTop:"-5px"}}
            data-target="#delete-user"
            onClick={() => {
              removeAlert();
              setModal({ isOpen: true });
            }}
          >
            <svg
              width="20px"
              className="mt-sm-md-icon"
              height="20px"
              viewBox=" 0 0 20 20"
              data-placement="top"
              data-toggle="tooltip"
              title="Delete User"
            >
              <use xlinkHref="./assets/images/delete.svg#delete-ico" />
            </svg>
          </Link>
        </td>
      </tr>
      <tr className="hide-table-padding" style={{ height: "0px !important" }}>
        <td className="d-none" />
        <td colSpan={12}>
          <div
            id={`${teamMember.id}teamMember`}
            className="collapse in p-3"
            style={{ width: "100%" }}
          >
            <div className="col-12">
              {/* <p className="text-white"></p> */}
              <div className="mt-2 mb-0">
                <h6
                  className="text-white text-left mb-4 mt-3"
                  style={{
                    fontSize: "18px !important",
                    marginLeft: "-10px",
                    textAlign: "left !important",
                  }}
                >
                  Edit Team Member
                </h6>
                <div>
                  <form method="POST" _lpchecked={1}>
                    <div className="row d-flex justify-content-start ">
                      <div className="col col-lg-6 col-12 p-1">
                        <div className="form-group form-group-mb m-0">
                          <label className="input-label-pos abs-label">First Name </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            required
                            value={teamMembersInfo.firstName}
                            onChange={(e) => editTeamInfo(e)}
                          />
                        </div>
                      </div>

                      <div className="col col-lg-8 col-12 p-1">
                        <div className="form-group form-group-mb m-0">
                          <label className="input-label-pos abs-label">
                            Email Address{" "}
                          </label>
                          <input
                            className="form-control input-dark-accnt personal-info"
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            required
                            value={teamMembersInfo.email}
                            onChange={(e) => editTeamInfo(e)}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col col-lg-4 col-12 p-1">
                        <div className="form-group form-group-mb m-0">
                          <label className="input-label-pos abs-label">
                            Account Type{" "}
                          </label>
                          <input
                            className="form-control input-dark-accnt personal-info"
                            type="text"
                            name="Account Type"
                            placeholder="Account Type"
                            required
                            value="Team Member"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col col-md-12 text-center my-3">
                  <button
                    className="btn btn-2 accnt-mngmt-btn mr-2 mb-2"
                    title="Edit"
                    data-toggle="collapse"
                    data-parent="#accordion1"
                    href={`#${teamMember.id}teamMember`}
                  >
                    Cancel
                  </button>
                  {loader && check ? (
                    <button
                      className="btn btn-3 accnt-mngmt-btn mb-2"
                      type="button"
                      disabled
                      style={{
                        background: "#1cb7f2",
                        font: "16px",

                        color: "white",
                      }}
                    >
                      <span
                        className="spinner-border spinner-border-sm text-light mx-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <strong>Updating...</strong>
                    </button>
                  ) : (
                    <button
                      onClick={updateTeamAccount}
                      className="btn btn-3 accnt-mngmt-btn mb-2"
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </td>
        <td>
          {modal.isOpen ? (
            <ConfirmDelete close={close} confirm={deleteConfimAccount} />
          ) : null}
        </td>
        <td>
          {passwordModal.open ? (
            <ResetPassword
              close={handleClose}
              appType={passwordModal.appName}
              user_id={userGenId}
              id={teamMember.id}
              showAlert={showAlert}
            />
          ) : null}
        </td>
      </tr>
    </>
  );
};

TeamMemberManagement.propTypes = {
  teamMember: PropTypes.object.isRequired,
  userGenId: PropTypes.string,
  deleteClientTeamAccount: PropTypes.func.isRequired,
  editClientTeamAccount: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  setLoader: PropTypes.func.isRequired,
  removeAlert: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loader: state.loader.loader,
});

export default connect(mapStateToProps, {
  deleteClientTeamAccount,
  editClientTeamAccount,
  setLoader,
  removeAlert,
})(TeamMemberManagement);
