import { GET_BRANDING_DATA, CLEAR_BRANDING_DATA } from "./Types";
import axios from "axios";
import { baseURL } from "../global/global";

// Fetching data for branding
export const getBrandingDataFromServer = (user) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ ...user });

  try {
    const res = await axios.post(`${baseURL}fetch-reseller`, body, config);
    if (res.data.status === true) {
      dispatch({ type: GET_BRANDING_DATA, payload: res.data.data });
    }
    else { dispatch({ type: GET_BRANDING_DATA, payload: "" }) }
  } catch (err) {
    console.log(err);
  }
};

// Remove Alert

export const clearBrandingData = () => (dispatch) => {
  dispatch({
    type: CLEAR_BRANDING_DATA,
  });
};
