import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import axios from "axios";

// Redux
import { connect } from "react-redux";
import { baseURL } from "../../global/global";
import { setLoader, removeLoader } from "../../actions/loader";
import { setAlert } from "../../actions/alert";

const Navigation = (props) => {
  const [user_id, setUserId] = useState("");

  const history = useHistory();

  useEffect(() => {
    setUserId(
      props.auth.loading || !props.auth.user.user_id
        ? ""
        : props.auth.user.user_id
    );
  }, [props.auth.user, props.auth.loading]);

  const mergeData = () => {
    const data = props.id
      ? {
          name: "newOne",
          user_id: user_id,
          preview_image: props.selectVideo[0].preview_image,
          id: props.id,
          data: props.selectVideo.map((value) => ({
            url: value.url,
            name: value.name,
            preview_image: value.preview_image,
          })),
        }
      : {
          name: "newOne",
          user_id: user_id,
          preview_image: props.selectVideo[0].preview_image,
          data: props.selectVideo.map((value) => ({
            url: value.url,
            name: value.name,
            preview_image: value.preview_image,
          })),
        };

    props.setLoader();
    mergingVideo(data);
  };

  // Save and Render
  const mergingVideo = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);

    try {
      const res = await axios.post(`${baseURL}create-merge`, body, config);

      if (res.data.status === true) {
        props.setAlert(res.data.message, "success");
        props.removeLoader();
        return history.push(`/my-merge-video`);
      } else if (res.data.status === false) {
        props.removeLoader();
      }
    } catch (err) {
      props.removeLoader();
      console.log(err.response);
    }
  };

  return (
    <ul id="inner-tab" className="nav nav-tabs d-flex align-items-center justify-content-center" style={{height:"60px"}}>
      <li className="nav-item mb-0">
        <a
          id="text-tab"
          className={"nav-link"}
          href="!#"
          onClick={(e) => e.preventDefault()}
        >
          {/* <span
            className="icon d-block mb-2"
            style={{ height: "20px", width: "20px" }}
          ></span> */}
          ReelMerge
        </a>
      </li>
      <li
        className="nav-item mb-0"
        style={{
          right: "10px",
          position: "absolute",
          top:"10px",
        }}
      >
        <div className="row align-items-center">
          <div className="col colmn-2">
            <div className="btn-wrapper d-flex justify-content-sm-end justify-content-center">
              {props.loader ? (
                <button
                  id="render"
                  className="btn btn-3 accnt-mngmt-btn mb-2"
                  disabled
                >
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <i className="fa fa-crosshairs" aria-hidden="true"></i>{" "}
                  Merging...
                </button>
              ) : (
                <button
                  id="render"
                  className="btn btn-3 accnt-mngmt-btn mb-2"
                  onClick={mergeData}
                  disabled={props.selectVideo.length > 0 ? false : true}
                >
                  <i className="fa fa-crosshairs" aria-hidden="true"></i> Merge
                </button>
              )}
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};

Navigation.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  removeLoader: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
});

export default connect(mapStateToProps, { setAlert, setLoader, removeLoader })(
  Navigation
);
