import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import BusinessNav from "../businessNav/BusinessNav";

const UdemyStyle = ({ user, loading }) => {
  const [accountType, setAccountType] = useState([]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  if (accountType.length > 0) {
    if (!(accountType.includes("bundle") || accountType.includes("dfy"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <HostingStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <BusinessNav />
              <div className="col-9 mb-5">
                <h6
                  className="text-white  mb-0 mt-5 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Udemy Style Website
                </h6>
                <div className="row bg-table justify-content-center">
                  <div className="col-8">
                    <div class="card fb-card border-0">
                      <div
                        class="card-header header-bg"
                        style={{ background: "#1cb7f2" }}
                      >
                        <h5 className="text-light">Udemy Style Website</h5>
                      </div>
                      <div class="card-body">
                        Download Udemy Style Website to showcase your courses.
                        Instant Access to Udemy Style Website Builder to Sell
                        Courses
                      </div>
                      <div class="card-footer p-0"></div>
                    </div>
                  </div>
                </div>

                <div className="row bg-table justify-content-center">
                  <div className="col-5 mt-2">
                    <div class="card fb-card border-0">
                      <div class="card-body">
                        {" "}
                        <a
                          className="btn btn-3 accnt-mngmt-btn "
                          style={{ fontSize: "0.9rem" }}
                          target="_blank"
                          href="https://acadeable.s3.amazonaws.com/Acadeable_files.zip"
                        >
                          <div>Download Setup</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HostingStyle>
  );
};

const HostingStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(UdemyStyle);
