import React, { useEffect, useState } from "react";
import "./style.css";
import SaveHeader from "../SaveHeader/SaveHeader";
// import SideBar from "../Sidebar/Sidebar";
import SideNav from "../../SideNav";
import queryString from "query-string";
import { Redirect } from "react-router-dom";

import Main from "./Main/Main";
import {
  applyObjectSelect,
  loadTemplate,
  get_into_initialState,
} from "../../../../actions/normalTemplateActions";

import { connect } from "react-redux";

function Editor(props) {
  const [is_client, setIsClient] = useState(false);
  const code = props.templateCode;

  const project_ids = queryString.parse(props.location.search).project_id;
  const template_ids = queryString.parse(props.location.search).template_id;

  useEffect(() => {
    if (props.auth.user) {
      setIsClient(
        props.auth.loading || !props.auth.user.is_client
          ? false
          : props.auth.user.is_client
      );
    }
  }, [props.auth.user, props.auth.loading]);

  useEffect(() => {
    if (project_ids) {
      props.loadTemplates({
        id: project_ids,
        name: "project",
      });
    } else if (template_ids) {
      props.loadTemplates({
        id: template_ids,
        name: "template",
      });
    }
  }, [props.loadTemplates, project_ids, template_ids]);

  useEffect(() => {
    return () => {
      props.getIntoInitialState();
    };
  }, []);

  if (project_ids === undefined && template_ids === undefined) {
    return <Redirect to="/create-videos" />;
  }

  if (is_client === true) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="editor bg-dark pad-rm" style={{minHeight:"100vh", height:"auto", overflowX:"hidden", overflowY:"auto", marginBottom:"45px", marginTop:"10px"}}>
      <div id="editor-wrapper" className="wrapper">
        <SaveHeader />

        <SideNav activeTab={"editor"} />

        <div id="editor-tab-wrap" className="tab-content"> 
          {code.length > 0 ? (
            <Main
              templateCode={code}
              select={props.applyObjectSelect}
              currentSeek={props.currentSeek}
              template_id={template_ids}
              project_id={project_ids}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    templateCode: state.normalTemplates.present.code,
    currentSeek: state.normalTemplates.present.currentSeek,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTemplates: (data) => dispatch(loadTemplate(data)),
    applyObjectSelect: (id) => dispatch(applyObjectSelect(id)),
    getIntoInitialState: () => dispatch(get_into_initialState()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Editor);
