import React, { Component } from "react";
import { useSelector } from "react-redux";

const Footer = () => {

  const rebrand = useSelector(state => state.brandingdata.brandingdata)
  const auth = useSelector(state => state.auth)
  return (
    <div>
      <footer id="app-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col colmn-1 col-lg-4 col-12 mb-lg-0 mb-3">
              <ul id="footer-nav" className="row mx-0">
                <li className="nav-item">
                  <a
                    href="https://reelapps.io/terms.html"
                    target="blank"
                    className="text-white pr-0"
                  >
                    Terms &amp; Conditions
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://reelapps.io/privacy.html"
                    target="blank"
                    className="text-white pr-0"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://support.vineasx.com/"
                    target="blank"
                    className="text-white pr-0"
                  >
                    Support
                  </a>
                </li>
              </ul>
            </div>
            <div className="col colmn-2 col-lg-5 col-12 mb-lg-0 mb-3">
              <div className="row inner-row align-items-center justify-content-lg-center mx-0">
                <div className="copyright pr-lg-4 order-lg-1 order-2 text-center">
                  <p className="text-white copyTxt">
                    © {(new Date().getFullYear())}, All Rights Reserved to <a href="https://vineasx.com/" target="_blank" rel="noreferrer">VineaSX Solutions LLC.</a> Developed and Maintained by
                    <a href="https://vega6.com/" target="_blank" className="ml-2">Vega6</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col colmn-2 col-lg-3 col-12">
              <div className="row inner-row align-items-center justify-content-lg-end mx-0">
                <div className="copyright pr-0 pr-lg-4 order-lg-1 order-2">
                  <a
                    className="text-white"
                    target="blank"
                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    href="https://reelapps.io/"
                  >
                    {auth.user ? auth?.user?.isWl === 1 ?

                      <img
                        src={rebrand?.reseller?.logo ? rebrand.reseller.logo : "./assets/images/video-reel.png"}
                        className="img-fluid"
                        alt="logo"
                        style={{ height: "54px", objectFit: "contain", maxWidth:"180px" }}
                      />

                      :
                      <img
                        src="./assets/images/reelapps.png"
                        className="img-fluid"
                        alt="logo"
                        style={{ height: "54px", objectFit: "contain" }}
                      />
                      : rebrand.reseller ?
                        <img
                          src={rebrand?.reseller?.logo ? rebrand.reseller.logo : "./assets/images/video-reel.png"}
                          className="img-fluid"
                          alt="logo"
                          style={{ height: "54px", objectFit: "contain" }}

                        />
                        :
                        <img
                          src="./assets/images/reelapps.png"
                          className="img-fluid"
                          alt="logo"
                          style={{ height: "54px", objectFit: "contain" }}
                        />
                    }
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
