import React, { useEffect, useState } from "react";
import { ReactComponent as DownArrow } from "../../../../../../assets/images/down-arrow.svg";
import { ReactComponent as TextBoldIcon } from "../../../../../../assets/images/text-bold.svg";
import { ReactComponent as TextItalicIcon } from "../../../../../../assets/images/text-italic.svg";
import { ReactComponent as TextUppercaseIcon } from "../../../../../../assets/images/text-upper.svg";
import { ReactComponent as TextLowercaseIcon } from "../../../../../../assets/images/text-lower.svg";
import { ReactComponent as TextUnderlineIcon } from "../../../../../../assets/images/text-underline.svg";
import { ReactComponent as TextStrikeIcon } from "../../../../../../assets/images/text-strike.svg";
import { ReactComponent as TextLeftIcon } from "../../../../../../assets/images/text-left.svg";
import { ReactComponent as TextCenterIcon } from "../../../../../../assets/images/text-center.svg";
import { ReactComponent as TextRightIcon } from "../../../../../../assets/images/text-right.svg";
import { ReactComponent as TextJustifyIcon } from "../../../../../../assets/images/text-justify.svg";
import { ReactComponent as TextLineHeightIcon } from "../../../../../../assets/images/ico-line-height.svg";
import FontColor from "./FontColor";
import FontBackgroundColor from "./FontBackgroundColor";
import { applyTextObjectProperties } from "../../../../../../actions/normalTemplateActions";
import { connect } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../../../../global/global";

function TextCustomization(props) {
  const [fonts, setFonts] = useState([]);

  let initialText = {
    fontFamily: "Poppins",
    fontSize: "24",
    fontWeight: "normal",
    fontStyle: "normal",
    textTransform: "lowercase",
    textDecoration: "none",
    color: "#ffffff",
    backgroundColor: "none",
    textAlign: "left",
    lineHeight: "1",
  };
  if (props.object.objectType === "text") {
    initialText = {
      fontFamily: props.object.styles.fontFamily,
      fontSize: props.object.styles.fontSize,
      fontWeight: props.object.styles.fontWeight,
      fontStyle: props.object.styles.fontStyle,
      textTransform: props.object.styles.textTransform,
      textDecoration: props.object.styles.textDecoration,
      color: props.object.styles.color,
      backgroundColor: props.object.styles.backgroundColor,
      textAlign: props.object.styles.textAlign,
      lineHeight: props.object.styles.lineHeight,
    };
  }
  const [text, setTextState] = useState(initialText);
  const buttons = {
    1: [
      {
        type: "bold",
        tooltip: "Text Bold",
        svg: <TextBoldIcon />,
        active: props.object.styles.fontWeight === "bold",
      },
      {
        type: "italic",
        tooltip: "Text Italic",
        svg: <TextItalicIcon />,
        active: props.object.styles.fontStyle === "italic",
      },
      {
        type: "uppercase",
        tooltip: "Text Uppercase",
        svg: <TextUppercaseIcon />,
        active: props.object.styles.textTransform === "uppercase",
      },
      {
        type: "lowercase",
        tooltip: "Text Lowercase",
        svg: <TextLowercaseIcon />,
        active: props.object.styles.textTransform === "lowercase",
      },
      {
        type: "underline",
        tooltip: "Text Underline",
        svg: <TextUnderlineIcon />,
        active: props.object.styles.textDecoration === "underline",
      },
      {
        type: "strikethrough",
        tooltip: "Text Strikethrough",
        svg: <TextStrikeIcon />,
        active: props.object.styles.textDecoration === "line-through",
      },
    ],
    2: [
      {
        type: "left",
        tooltip: "Align Text Left",
        svg: <TextLeftIcon />,
        active: props.object.styles.textAlign === "left",
      },
      {
        type: "center",
        tooltip: "Align Text Center",
        svg: <TextCenterIcon />,
        active: props.object.styles.textAlign === "center",
      },
      {
        type: "right",
        tooltip: "Align Text Right",
        svg: <TextRightIcon />,
        active: props.object.styles.textAlign === "right",
      },
      {
        type: "justify",
        tooltip: "Text Align Justify",
        svg: <TextJustifyIcon />,
        active: props.object.styles.textAlign === "justify",
      },
      {
        type: "lineHeight",
        tooltip: "Text Align Justify",
        svg: <TextLineHeightIcon />,
      },
    ],
  };

  const [buttonActive, setButtonActive] = useState(buttons);
  const handleNumber = (e) => {
    const newObj = {
      id: props.object.id,
      type: "lineHeight",
      value: e.target.value,
    };
    props.applyTextObjectProperties(newObj);
    setTextState({ ...text, lineHeight: e.target.value })
    // setTextState({
    //   ...text,
    //   fontFamily:e.target.value
    // })
  };

  useEffect(() => {
    setTextState(initialText);
    setButtonActive(buttons);
  }, [props.object]);

  const handleChange = (e) => {
    const newObj = {
      id: props.object.id,
      type: "fontSize",
      value: e.target.value,
    };
    props.applyTextObjectProperties(newObj);
    // setTextState({
    //   ...text,
    //   fontSize:e.target.value
    // })
  };

  const handleFontChange = (e) => {
    const newObj = {
      id: props.object.id,
      type: "fontFamily",
      value: e.target.value,
    };
    props.applyTextObjectProperties(newObj);
    // setTextState({
    //   ...text,
    //   fontFamily:e.target.value
    // })
  };

  const handleButtonClick = (e, type) => {
    const newObj = {
      id: props.object.id,
      type: type,
      value: type,
    };
    props.applyTextObjectProperties(newObj);
  };

  const gettingFonts = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post(`${baseURL}/api/fetch-fonts`, config);

      if (res.data.status === true) {
        setFonts(res.data.data);
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    if (fonts.length <= 0) {
      gettingFonts();
    }
  }, [gettingFonts]);

  // const fonts = [
  //   { id: "1", name: "Roboto", value: "Roboto" },
  //   { id: "2", name: "Poppins", value: "Poppins" },
  //   { id: "3", name: "Alata", value: "Alata" },
  //   { id: "4", name: "Open Sans", value: "Open Sans" },
  //   { id: "5", name: "Noto Sans", value: "Noto Sans" },
  //   { id: "6", name: "Russo One", value: "Roboto" },
  //   { id: "7", name: "Noto Sans", value: "Noto Sans" },
  //   { id: "8", name: "Montserrat", value: "Roboto" },
  //   { id: "9", name: "Do Hyeon", value: "Do Hyeon" },
  //   { id: "10", name: "Roboto Condensed", value: "Roboto Condensed" },
  //   { id: "11", name: "Source Sans", value: "Source Sans" },
  //   { id: "12", name: "Oswald", value: "Oswald" },
  //   { id: "13", name: "Mukta", value: "Mukta" },
  //   { id: "14", name: "Baloo Tamma 2", value: "Baloo Tamma 2" },
  //   { id: "15", name: "Roboto Mon", value: "Raleway" },
  //   { id: "16", name: "PT Sans", value: "PT Sans" },
  //   { id: "17", name: "Roboto Slab", value: "Merriweather" },
  //   { id: "18", name: "Ubuntu", value: "Ubuntu" },
  //   { id: "19", name: "Playfair", value: "Playfair" },
  //   { id: "20", name: "Open Sans Condensed", value: "Open Sans Condensed" },
  //   { id: "21", name: "Hind", value: "Hind" },
  //   { id: "22", name: "Nunito", value: "Nunito" },
  //   { id: "23", name: "Lora", value: "Lora" },
  //   { id: "24", name: "PT Serif", value: "PT Serif" },
  //   { id: "25", name: "Noto Sans KR", value: "=Noto Sans KR" },
  //   { id: "26", name: "Work Sans", value: "Work Sans" },
  //   { id: "27", name: "Rubik", value: "Balsamiq Sans" },
  //   { id: "28", name: "Noto Serif", value: "Noto Serif" },
  //   { id: "29", name: "Fira Sans", value: "Fira Sans" },
  //   { id: "30", name: "Nanum Gothic", value: "Nanum Gothic" },
  //   { id: "31", name: "Titillium Web", value: "Titillium Web" },
  //   { id: "32", name: "Nunito Sans", value: "Nunito Sans" },
  //   { id: "33", name: "Teko", value: "Teko" },
  //   { id: "34", name: "Quicksand", value: "Quicksand" },
  //   { id: "35", name: "Noto Sans TC", value: "Noto Sans TC" },
  //   { id: "36", name: "Londrina Solid", value: "Londrina Solid" },
  //   { id: "37", name: "Yanone Kaffeesatz", value: "Yanone Kaffeesatz" },
  //   { id: "38", name: "Heebo", value: "Heebo" },
  //   { id: "39", name: "Inconsolata", value: "Inconsolata" },
  //   { id: "40", name: "Anton", value: "Anton" },
  //   { id: "41", name: "Barlow", value: "Barlow" },
  //   { id: "42", name: "Oxygen", value: "Oxygen" },
  //   { id: "43", name: "PT Sans Narrow", value: "PT Sans Narrow" },
  //   { id: "44", name: "Hind Siliguri", value: "Hind Siliguri" },
  //   { id: "45", name: "Dosis", value: "Dosis" },
  //   { id: "46", name: "Libre Caslon Display", value: "Libre Caslon Display" },
  //   { id: "47", name: "Karla", value: "Karla" },
  // ];
  const but = [];
  for (const indexes in buttonActive) {
    const item = buttonActive[indexes].map((element, index) => {
      let i = false;
      if (element.type === "lineHeight") {
        i = (
          <div key={index} className="input-group-prepend mr-0 position-relative">
            <span className="icon">
              <svg width="14px" height="15px" viewBox="0 0 14 15">
                {element.svg}
              </svg>
            </span>
            <input
              id="t-lineheight"
              className="form-control input-dark text-white edit-input-height"
              type="number"
              onChange={handleNumber}
              value={text.lineHeight}
              name="lineHeight"


            />
          </div>
        );
      } else {
        i = (
          <button
            key={index}
            className={`txt-edit-btn text-white input-dark ml-0 ${element.active ? "active" : ""
              }`}
            data-toggle="tooltip"
            title={element.tooltip}
            onClick={(e) => handleButtonClick(e, element.type)}
          >
            <svg width="22px" height="12px" viewBox="0 0 22 12">
              {element.svg}
            </svg>
          </button>
        );
      }
      return i;
    });
    but.push(
      <div
        key={indexes}
        className="text-alignment form-group mb-2 d-flex flex-xl-nowrap flex-wrap"
      >
        {item}
      </div>
    );
  }

  return (
    <div className="text-customization">
      <span
        className="label text-white mb-2"
        style={{ fontFamily: "Roboto", fontWeight: "100" }}
      >
        Font
      </span>
      <div className="text-family form-group mb-2 position-relative">
        <select
          id="sel-font"
          className="form-control input-dark edit-input-height"
          name="f-family"
          onChange={(e) => handleFontChange(e)}
        >
          {fonts.map((element) => (
            <option
              value={element.value}
              key={element.id}
              defaultValue={element.name === text.fontFamily}
            >
              {element.name}
            </option>
          ))}
        </select>
        <span className="icon">
          <svg width="12px" height="12px" viewBox="0 0 12 12">
            <DownArrow />
          </svg>
        </span>
      </div>
      <div className="text-weight-size form-group mb-2 d-flex flex-xl-nowrap flex-wrap">
        <div className="input-wrapper w-100 position-relative">
          <input
            id="f-size"
            type="number"
            className="form-control input-dark edit-input-height"
            name="f-size"
            value={text.fontSize}
            onChange={(e) => handleChange(e)}
          />
          <span className="icon">
            <svg width="12px" height="12px" viewBox="0 0 12 12">
              <DownArrow />
            </svg>
          </span>
        </div>
      </div>
      {but}
      <hr className="editor_hr" />
      <span className="label text-white mb-2">Font Color</span>
      <FontColor color={text.color} object={props.object} />
      <hr className="editor_hr" />
      <span className="label text-white mb-2">Font Background</span>
      <FontBackgroundColor
        BgColor={text.backgroundColor}
        object={props.object}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    applyTextObjectProperties: (newObj) =>
      dispatch(applyTextObjectProperties(newObj)),
  };
};
export default connect(null, mapDispatchToProps)(TextCustomization);
