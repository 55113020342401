import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { onConnectInte } from '../../../actions/WhiteLabelActions'
import { setAlert } from '../../../actions/alert'

const CreateIntegrationModal = ({ show, handleClose, fetchSocial }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth.user)
    const [loader, setLoader] = useState({
        submit: false,
        fetch: false
    })
    const [state, setState] = useState({
        name: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { ...state }
        data.user_id = user.user_id
        if (!state.secretKey.startsWith('sk')) {
            dispatch(setAlert("Please enter valid secret key", 'danger'));
            return;
        }


        setLoader({
            ...loader,
            submit: true
        })

        dispatch(onConnectInte("stripe-connect", data, fetchSocial, handleClose, loader, setLoader))
    }

    return (
        <Modal className="white_label_modal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="white_Label_modal_wrapper">
                    <div className="white_label_profile">
                        <div className="white-titleBar-left">
                            <h2 className='text-capitalize'>Connect Stripe</h2>
                        </div>
                        <span className="white_modalClose" onClick={handleClose}><AiOutlineClose /></span>

                    </div>
                    <form onSubmit={handleSubmit} className='mt-3'>
                        <div className="white-input-wrap mt-0">
                            <label htmlFor="">Name</label>
                            <div className="white-outer ">
                                <input
                                    type="text"
                                    className="white_inp"
                                    placeholder="Enter Your Name"
                                    name='name'
                                    value={state.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="white-input-wrap">
                            <label htmlFor="">Secret Key</label>
                            <div className="inp-outer">
                                <input
                                    className="white_inp"
                                    type="text"
                                    name='secretKey'
                                    value={state.secretKey}
                                    placeholder='Enter Secret Key'
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="white-input-wrap text-center text-sm-end">
                            <button className="white-theme-btn bdr mr-2" type='button' onClick={handleClose}><span>Cancel</span></button>
                            <button className="white-theme-btn" type='submit'><span>{loader.submit ? <>Connecting <FaSpinner className="spin" /></> : "Connect"}</span></button>
                        </div>
                    </form>
                </div>

            </Modal.Body>
        </Modal >
    )
}

export default CreateIntegrationModal