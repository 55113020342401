import React, { useState, useEffect } from "react";
import { ReactComponent as TextIcon } from "../../../../../../assets/images/text.svg";
import { ReactComponent as BackgroundIcon } from "../../../../../../assets/images/background.svg";
import { ReactComponent as MediaIcon } from "../../../../../../assets/images/media.svg";
import { ReactComponent as AudioIcon } from "../../../../../../assets/images/audio.svg";
import { ReactComponent as AnimationIcon } from "../../../../../../assets/images/animation.svg";
import { ReactComponent as TransitionIcon } from "../../../../../../assets/images/transition.svg";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import axios from "axios";

// Redux
import { connect } from "react-redux";
import { baseURL } from "../../../../../../global/global";
import { setLoader, removeLoader } from "../../../../../../actions/loader";
import { setAlert } from "../../../../../../actions/alert";

function Navigation(props) {
  const [user_id, setUserId] = useState("");
  const [saveRenderCheck, setSaveRenderCheck] = useState("");

  const history = useHistory();

  useEffect(() => {
    setUserId(
      props.auth.loading || !props.auth.user.user_id
        ? ""
        : props.auth.user.user_id
    );
  }, [props.auth.user, props.auth.loading]);

  const handleClick = (e, type) => {
    e.preventDefault();
    props.handleClick(type);
  };

  // Saving Data
  const saveToServer = () => {
    const data = {
      type: "save",
      user_id: user_id,
      project_name: "projectName",
      audio_url: { status: false, url: "" },
      data: props.normalTemplates.present.code,
      template_id: props.template_id ? props.template_id : "",
      project_id: props.project_id ? props.project_id : "",
    };
    setSaveRenderCheck("save");
    props.setLoader();
    saveAndRenderToServer(data);
  };

  const renderToServer = () => {
    const data = {
      type: "render",
      user_id: user_id,
      project_name: "projectName",
      audio_url: { status: false, url: "" },
      data: props.normalTemplates.present.code,
      template_id: props.template_id ? props.template_id : "",
      project_id: props.project_id ? props.project_id : "",
    };

    setSaveRenderCheck("render");
    props.setLoader();
    saveAndRenderToServer(data);
  };

  // Save and Render
  const saveAndRenderToServer = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);

    try {
      const res = await axios.post(
        `${baseURL}save-legacy-campaign`,
        body,
        config
      );

      if (res.data.status === true) {
        props.setAlert(res.data.message, "success");
        props.removeLoader();
        return history.push(`/my-videos`);
      } else if (res.data.status === false) {
        props.removeLoader();
      }
    } catch (err) {
      props.removeLoader();
      console.log(err.response);
    }
  };

  return (
    <ul id="inner-tab" className="nav nav-tabs">
      <li className="nav-item mb-0">
        <a
          id="text-tab"
          className={
            "nav-link position-relative " + (props.activeMenu === "text" ? "active" : "")
          }
          href="/"
          onClick={(e) => handleClick(e, "text")}
        >
          <span className="icon d-block mb-2">
            <svg width="20px" height="20px" viewBox="0 0 20 20">
              <TextIcon />
            </svg>
          </span>
          Text
        </a>
      </li>
      <li className="nav-item mb-0">
        <a
          id="media-tab"
          className={
            "nav-link position-relative " + (props.activeMenu === "media" ? "active" : "")
          }
          href="/"
          onClick={(e) => handleClick(e, "media")}
        >
          <span className="icon d-block mb-2">
            <svg width="20px" height="20px" viewBox="0 0 20 20">
              <MediaIcon />
            </svg>
          </span>
          Media
        </a>
      </li>
      <li className="nav-item mb-0">
        <a
          id="audio-tab"
          className={
            "nav-link position-relative " + (props.activeMenu === "audio" ? "active" : "")
          }
          href="/"
          onClick={(e) => handleClick(e, "audio")}
        >
          <span className="icon d-block mb-2">
            <svg width="20px" height="20px" viewBox="0 0 20 20">
              <AudioIcon />
            </svg>
          </span>
          Audio
        </a>
      </li>
      <li
        className="nav-item d-flex align-items-center justify-content-center"
      >
        <div className="row align-items-center justify-content-center">
          <div className="col colmn-2 col-6" style={{minWidth:"150px"}}>
            <div className="btn-wrapper d-flex justify-content-sm-end justify-content-center">
              {props.loader ? (
                <button
                  id="save"
                  className="btn btn-2 accnt-mngmt-btn mr-xl-2 mb-2"
                  disabled
                >
                  {saveRenderCheck === "save" ? (
                    <>
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <i className="fa fa-floppy-o" aria-hidden="true"></i>{" "}
                      Saving...
                    </>
                  ) : (
                    <>
                      <i className="fa fa-floppy-o" aria-hidden="true"></i> Save
                    </>
                  )}
                </button>
              ) : (
                <button
                  id="save"
                  className="btn btn-2 accnt-mngmt-btn mr-xl-2 mb-2"
                  onClick={saveToServer}
                >
                  <i className="fa fa-floppy-o" aria-hidden="true"></i> Save
                </button>
              )}
            </div>
          </div>

          <div className="col colmn-2 col-6" style={{minWidth:"150px"}}>
            <div className="btn-wrapper d-flex justify-content-sm-end justify-content-center">
              {props.loader ? (
                <button
                  id="render"
                  className="btn btn-3 accnt-mngmt-btn mb-2"
                  disabled
                >
                  {saveRenderCheck === "render" ? (
                    <>
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <i className="fa fa-crosshairs" aria-hidden="true"></i>{" "}
                      rendering...
                    </>
                  ) : (
                    <>
                      <i className="fa fa-crosshairs" aria-hidden="true"></i>{" "}
                      Render
                    </>
                  )}
                </button>
              ) : (
                <button
                  id="render"
                  className="btn btn-3 accnt-mngmt-btn mb-2"
                  onClick={renderToServer}
                >
                  <i className="fa fa-crosshairs" aria-hidden="true"></i> Render
                </button>
              )}
            </div>
          </div>
        </div>
      </li>

      {/*<li className="nav-item mb-0">*/}
      {/*    <a id="transition-tab" className={"nav-link " + (props.activeMenu === 'transition' ? "active" : "")} href="/" onClick={(e) => handleClick(e,'transition')}>*/}
      {/*        <span className="icon d-block mb-2">*/}
      {/*            <svg width="20px" height="20px" viewBox="0 0 20 20">*/}
      {/*                <TransitionIcon />*/}
      {/*            </svg>*/}
      {/*        </span>*/}
      {/*        Transition*/}
      {/*    </a>*/}
      {/*</li>*/}
    </ul>
  );
}

Navigation.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  removeLoader: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  normalTemplates: state.normalTemplates,
});

export default connect(mapStateToProps, { setAlert, setLoader, removeLoader })(
  Navigation
);
