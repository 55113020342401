import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import BusinessNav from "../businessNav/BusinessNav";

const ClientContract = ({ user, loading }) => {
  const [accountType, setAccountType] = useState([]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  if (accountType.length > 0) {
    if (!(accountType.includes("bundle") || accountType.includes("business"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <ContractStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <BusinessNav />
              <div className="col-9 mb-5">
                <h6
                  className="text-white  mb-0 mt-5 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Client Contract
                </h6>
                <div className="row bg-table justify-content-center">
                  <div className="col-8">
                    <div class="card fb-card border-0">
                      <div
                        class="card-header header-bg"
                        style={{ background: "#1cb7f2" }}
                      >
                        <h5 className="text-light">Client Contract</h5>
                      </div>
                      <div class="card-body">
                        Download our "Client Contract" template. This is a docx
                        file, works best with MS Word. Edit the doc and add your
                        business name, address and other details. Fill the empty
                        blank space with your service, eg Video Marketing, Local
                        Reputation Management, Social Media Marketing, Design &
                        Creatives, Ads Management and so on.
                      </div>
                      <div class="card-footer p-0"></div>
                    </div>
                  </div>
                </div>

                <div className="row bg-table justify-content-center">
                  <div className="col-4 mt-2">
                    <div class="card fb-card border-0">
                      <div class="card-header text-center">
                        Client Brief Pitch Template
                      </div>
                      <div class="card-body">
                        {" "}
                        <a
                          className="btn btn-3 accnt-mngmt-btn p-2"
                          style={{ fontSize: "0.9rem" }}
                          href="!#"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location =
                              "https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Client-Brief-Form.doc";
                          }}
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mt-2">
                    <div class="card fb-card border-0">
                      <div class="card-header text-center">
                        Client Service Agreement
                      </div>
                      <div class="card-body">
                        {" "}
                        <a
                          className="btn btn-3 accnt-mngmt-btn p-2"
                          style={{ fontSize: "0.9rem" }}
                          href="!#"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location =
                              "https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Services+Agreement.doc";
                          }}
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mt-2">
                    <div class="card fb-card border-0">
                      <div class="card-header text-center">
                        Client Meeting Setup Template
                      </div>
                      <div class="card-body">
                        {" "}
                        <a
                          className="btn btn-3 accnt-mngmt-btn p-2"
                          style={{ fontSize: "0.9rem" }}
                          href="!#"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location =
                              "https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Meeting-Doc.doc";
                          }}
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ContractStyle>
  );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(ClientContract);
