import React, { useState } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import queryString from "query-string";
import { connect, useSelector } from "react-redux";
import { reset_password } from "../../actions/auth";
import PropTypes from "prop-types";
import Alert from "../alert/Alert";
import { setAlert } from "../../actions/alert";
import { setLoader, removeLoader } from "../../actions/loader";

function ResetPassword({
  location,
  reset_password,
  isAuthenticated,
  history,
  setAlert,
  loader,
  setLoader,
  removeLoader,
}) {
  const [userPassword, setUserPassword] = useState({
    password: "",
    confirm_password: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const rebrand = useSelector(state => state.brandingdata.brandingdata)

  const { password, confirm_password } = userPassword;
  const token = queryString.parse(location.search).token;

  const changePassword = (e) => {
    setUserPassword({ ...userPassword, [e.target.name]: e.target.value });
    setPasswordError("");
  };

  const isValidPassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);

    if (password.length < minLength) return "Password must be at least 8 characters long.";
    if (!hasUpperCase) return "Password must contain at least one uppercase letter.";
    if (!hasLowerCase) return "Password must contain at least one lowercase letter.";
    if (!hasNumber) return "Password must contain at least one number.";
    if (!hasSpecialChar) return "Password must contain at least one special character.";

    return "";
  };

  const onChangePassword = async (e) => {
    e.preventDefault();
    setLoader();

    const validationError = isValidPassword(password);
    if (validationError) {
      removeLoader();
      setPasswordError(validationError);
      setAlert(validationError, "danger");
      return;
    }

    if (password !== confirm_password) {
      removeLoader();
      setAlert("Passwords do not match", "danger");
      return;
    } else if (!token) {
      removeLoader();
      setAlert("Token is missing. Please check your email.", "danger");
      return;
    } else {
      reset_password(userPassword, token, history);
      setAlert("Password change successfully", "success");

    }
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="login-page">
      <section id="login-content">
        <div className="container">
          <div className="row mx-sm-auto align-items-center">
            <div className="col colmn-1 col-lg-6 col-md-9 col-sm-10 col-12 mx-auto pr-lg-5">
              <div className="content-wrapper text-center">
                <div className="img-wrapper mb-5">
                  <img
                    className="img-fluid"
                    src={rebrand?.reseller?.logo || "./assets/images/video-reel.png"}
                    alt="Logo"
                    style={{ height: "56px", objectFit: "contain" }}
                  />
                </div>
                <h4 className="text-white font-weight-normal">
                  Create Gorgeous Short-Videos for Social Media, Ads & more using premium templates.
                </h4>
              </div>
            </div>
            <div className="col colmn-2 col-lg-6 col-md-9 col-sm-10 col-12 mx-auto pl-lg-5">
              <form id="login-form" onSubmit={onChangePassword}>
                <div className="form-title text-center">
                  <h2 className="text-white textAcc alt">Reset Your Password</h2>
                  <div className="d-flex justify-content-center align-items-center hdMob" style={{ height: "7rem", width: "100%", marginTop: "10px" }}>
                    {/* <Alert authStyle={"authStyle"} /> */}
                  </div>
                </div>
                {passwordError && <div className="alert alert-danger">{passwordError}</div>}
                <div className="input-group mb-4">
                  <input
                    id="password"
                    className="form-control"
                    type="password"
                    name="password"
                    autoComplete="off"
                    required
                    value={userPassword.password}
                    onChange={changePassword}
                    style={{
                      backgroundImage: "url('data:image/png;base64,...')",
                      backgroundRepeat: "no-repeat",
                      backgroundAttachment: "scroll",
                      backgroundSize: "16px 18px",
                      backgroundPosition: "98% 50%",
                    }}
                  />
                  <label htmlFor="password" className="text-white">Password</label>
                  <span className="underline"> </span>
                </div>
                <div className="input-group mb-4">
                  <input
                    id="confirm-password"
                    className="form-control"
                    type="password"
                    name="confirm_password"
                    autoComplete="off"
                    required
                    value={userPassword.confirm_password}
                    onChange={changePassword}
                    style={{
                      backgroundImage: "url('data:image/png;base64,...')",
                      backgroundRepeat: "no-repeat",
                      backgroundAttachment: "scroll",
                      backgroundSize: "16px 18px",
                      backgroundPosition: "98% 50%",
                    }}
                  />
                  <label htmlFor="confirm-password" className="text-white">Confirm Password</label>
                  <span className="underline"> </span>
                </div>
                <div className="btn-wrapper text-center mb-4">
                  {loader ? (
                    <button className="btn btn-1 text-white text-uppercase" type="button" style={{ padding: "15px", fontWeight: "700", maxHeight: "50px" }} disabled>
                      <span className="spinner-border spinner-border-sm text-light mx-2" role="status" aria-hidden="true"></span>
                      <strong>Loading...</strong>
                    </button>
                  ) : (
                    <button className="btn btn-1 text-white text-uppercase" type="submit">Reset</button>
                  )}
                </div>
                <div className="form-footer text-center">
                  <Link to="/">
                    <p className="text-white sm-txt">Have Login?</p>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

ResetPassword.propTypes = {
  reset_password: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  setLoader: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  removeLoader: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loader: state.loader.loader,
});

export default connect(mapStateToProps, {
  reset_password,
  setAlert,
  removeLoader,
  setLoader,
})(withRouter(ResetPassword));
