import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";

function Logo({ brandingData }) {
  const [logo, setLogo] = useState("");
  useEffect(() => {
    if (brandingData.brandingdata.reseller) {
      setLogo(
        !brandingData.loading || !brandingData.brandingdata.reseller
          ? brandingData.brandingdata.reseller.logo
          : ""
      );
    }
    else { setLogo("") }
  }, [brandingData.brandingdata]);

  return (
    <>
      <Link className="navbar-brand p-0" to="/dashboard">
        <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "start", maxWidth: "200px" }}>
          <img
            className=""
            src={`${logo !== "" ? logo : "./assets/images/video-reel.png"}`}
            alt="Logo"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </div>

        {/* <img className="img-fluid videoreel-favicon-icon" src={require("src/assets/images/video-reel.svg")} alt="App Logo" /> */}
      </Link>
    </>
  );
}

const mapStateToProps = (state) => ({
  brandingData: state.brandingdata,
});

export default connect(mapStateToProps)(Logo);
