import React, { useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { login } from "../../actions/auth";
import { setLoader } from "../../actions/loader";
import PropTypes from "prop-types";
import Alert from "../alert/Alert";
import { Helmet } from "react-helmet";
import TitleBar from "../Common/TitleBar";
function Login({
  auth: { isAuthenticated, loading },
  login,
  history,
  loader,
  setLoader,
}) {
  const [userLogin, setUserLogin] = useState({ email: "", password: "" });
  const rebrand = useSelector(state => state.brandingdata.brandingdata)
  const onChange = (e) =>
    setUserLogin({ ...userLogin, [e.target.name]: e.target.value });

  const onLogin = async (e) => {
    e.preventDefault();
    setLoader();
    login(userLogin.email, userLogin.password, history);
  };
  if (isAuthenticated && !loading) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="login-page">
      <TitleBar title="Login" />
      <section id="login-content">
        <div className="container">
          <div className="row mx-sm-auto align-items-center login-justify">
            <div className="col colmn-1 col-lg-6 col-md-9 col-sm-10 col-12 mx-auto pr-lg-5">
              <div className="content-wrapper text-center">
                <div className="img-wrapper mb-5">
                  <img
                    className="img-fluid"
                    src={rebrand?.reseller?.logo || "./assets/images/video-reel.png"}
                    alt="Logo"
                    style={{ height: "56px", objectFit: "contain" }}
                  />
                </div>
                <h4 className="text-white font-weight-normal loginH4">
                  Create Gorgeous Short-Videos for Social Media, Ads & more
                  using premium templates.
                </h4>
              </div>
            </div>
            <div
              className="col colmn-2 col-lg-6 col-md-9 col-sm-10 col-12 mx-auto pl-lg-5"
            // style={{ minHeight: "31rem" }}
            >
              <form id="login-form" onSubmit={(e) => onLogin(e)}>
                <div className="form-title text-center">
                  <h2 className="text-white textAcc mb-5">Login to Your Account Now</h2>
                  <div
                    style={{
                      maxHeight: "7rem",
                      width: "100%",
                      marginTop: "10px",
                    }}
                    className="d-flex justify-content-center align-items-center hdMob"
                  >
                    {/* <Alert authStyle={"authStyle"} /> */}
                  </div>
                </div>
                <div className="input-group mb-4">
                  <input
                    id="email"
                    className="form-control"
                    type="email"
                    name="email"
                    autoComplete="off"
                    required
                    value={userLogin.email}
                    onChange={(e) => onChange(e)}
                  />
                  <label htmlFor="username" className="text-white">
                    Username
                  </label>
                  <span className="underline"> </span>
                </div>
                <div className="input-group mb-4">
                  <input
                    id="pass"
                    className="form-control"
                    type="password"
                    name="password"
                    autoComplete="off"
                    required
                    value={userLogin.password}
                    onChange={(e) => onChange(e)}
                  />
                  <label htmlFor="password" className="text-white">
                    Password
                  </label>
                  <span className="underline"> </span>
                </div>
                <div className="btn-wrapper text-center mb-4">
                  {loader ? (
                    <button
                      className="btn btn-1 text-white text-uppercase"
                      type="button"
                      style={{
                        padding: "15px",
                        fontWeight: "700",
                        maxHeight: "50px",
                      }}
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm text-light mx-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Signing In...
                    </button>
                  ) : (
                    <button className="btn btn-1 text-white text-uppercase">
                      Sign In
                    </button>
                  )}
                </div>
                <div className="form-footer text-center">
                  <Link to="/forget-password">
                    <p className="text-white sm-txt">Forgot your password?</p>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
});

Login.propTypes = {
  isAuthenticated: PropTypes.object,
  login: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  loader: PropTypes.bool,
};

export default connect(mapStateToProps, { login, setLoader })(
  withRouter(Login)
);
